import React from 'react';
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {Formik, Form} from "formik";
import * as yup from "yup";

import {Grid} from "@mui/material";

import Page from "components/page/Page";
import SaveButton from "components/button/SaveButton";
import CancelButtonStyled from "components/button/CancelButtonStyled";
import ActionsContainer from "components/containers/ActionsContainer";

import DeclarationSettings from "./components/DeclarationSettings";
import OperatorInformations from "./components/OperatorInformations";

import {create} from 'api/compliance/dac7/create'

function DAC7Create(props) {
    const history = useHistory();

    const organization = props.currentOrganization;

    const validationSchema = yup.object({
        organizationName: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.organizationName.error.required'})),
        registrationName: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.registrationName.error.required'})),
        registrationNumber: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.registrationNumber.error.required'})),
        taxNumberCountry: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.taxNumberCountry.error.required'})),
        taxIdentificationNumber: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.taxIdentificationNumber.error.required'})),
        vatNumber: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.vatNumber.error.required'})),
        registrationAddress: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.registrationAddress.error.required'})),
        period: yup.number()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.period.error.required'})),
        declarationCountry: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.declarationCountry.error.required'})),
        destinationCountry: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.destinationCountry.error.required'})),
        operatorTaxResidence: yup.string()
            .required(props.intl.formatMessage({id: 'compliance.dac7.form.field.operatorTaxResidence.error.required'}))
    });

    const initialValues = {
        'organizationName': organization?.name,
        'registrationName': organization?.registrationName,
        'registrationNumber': organization?.registrationNumber,
        'taxNumberCountry': organization?.taxNumberCountry,
        'taxIdentificationNumber': organization?.taxIdentificationNumber,
        'vatNumber': organization?.vatNumber,
        'registrationAddress': organization?.registrationAddress,
        'declarationCountry': '',
        'destinationCountry': '',
        'operatorTaxResidence': '',
        'period': new Date().getFullYear() -1 // last year
    }

    return (
        <Page titleId={'compliance.dac7.form.title'}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange
                validateOnMount
                enableReinitialize
                onSubmit={(values, {setSubmitting}) => {
                    create(values).then((r) => {
                        setSubmitting(true);
                    }).then(() => {
                        history.push('/compliance')
                    }).finally(() => {
                        setSubmitting(false);
                    })
                }}
            >
                {({
                      isSubmitting,
                      isValid,
                }) => (
                    <Form style={{ display: 'flex', justifyContent:'center' }}>
                        <Grid
                            container
                            item
                            xs={12}
                            lg={6}
                            direction='column'
                            rowSpacing={6}
                        >
                            <Grid item container>
                                <OperatorInformations />
                            </Grid>
                            <Grid item container>
                                <DeclarationSettings />
                            </Grid>
                            <Grid item>
                                <ActionsContainer>
                                    <CancelButtonStyled
                                        disabled={isSubmitting}
                                        onClick={() => history.push('/compliance')}
                                    >
                                        <FormattedMessage id='compliance.dac7.form.actions.cancel' />
                                    </CancelButtonStyled>
                                    <SaveButton
                                        type='submit'
                                        disabled={isSubmitting || !isValid}
                                        loading={isSubmitting}
                                    >
                                        <FormattedMessage id='compliance.dac7.form.actions.submit' />
                                    </SaveButton>
                                </ActionsContainer>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Page>
    );
}

const mapStateToProps = state => {
    return {
        currentOrganization: state.currentOrganization.retrieved ?? null
    };
};

export default connect(mapStateToProps)(injectIntl(DAC7Create));

