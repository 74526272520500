import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/material/styles";

import {
    black500,
    blue25,
    blueColor,
    greyishBlue300,
    greyishBlue50,
    red50,
    red500
} from "assets/jss/main";

const StyledDiv = styled('div')(({ theme, disabled, isValid, isMulti}) => ({
    width: isMulti ? '160px' : '100%',
    height: isMulti ? '160px' : '252px',
    maxHeight: isMulti ? '160px' : '252px',
    minHeight: '252px',
    minWidth: '252px',
    ...isMulti && {
        maxWidth: '160px',
        minHeight: 'unset',
        minWidth: 'unset',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px dashed',
    borderColor: blueColor,
    background: blue25,
    position: 'relative',
    'p': {
        color: black500,
        marginTop: 0,
        ...isMulti && {
            marginBottom: 0, 
            fontSize: 14,  
            lineHeight:'normal', 
        },
    },
    ...disabled && {
        borderColor: greyishBlue300,
        background: greyishBlue50,
        'p' : {
            marginTop: 0,
            color: greyishBlue300,
            ...isMulti && {
                marginBottom: 0,
                fontSize: 14,
                lineHeight:'normal',
            },
        }
    },
    ...isValid === false && {
        borderColor: red500,
        background: red50
    }
}));


const StyledImportZone = forwardRef(({ ...props }, ref) => {
    return (
        <StyledDiv
            {...props}
            ref={ref}
            onClick={props.disabled ? undefined : props.onClick}
            onDrop={props.disabled ? undefined : props.onDrop}
        >
            {props.children}
        </StyledDiv>
    );
});

StyledImportZone.propTypes = {
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    isMulti: PropTypes.bool
};

export default StyledImportZone;
