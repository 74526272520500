import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {stockEasyOffer} from "../../../../../domain/enum/offers";

TotalSelectedActionMessage.propTypes = {
    offer: PropTypes.string.isRequired,
    totalItems: PropTypes.number,
    isTotalSelected: PropTypes.bool
};

function TotalSelectedActionMessage({offer, totalItems, isTotalSelected = false}) {
    if (isTotalSelected) {
        return <FormattedMessage id={"retailer.product.list.cancelSelect"} />
    } else if (offer === stockEasyOffer) {
        return <FormattedMessage id={"retailer.product.list.selectTotal.stockeasy"} />;
    } else {
        return (
            <FormattedMessage
                id={"retailer.product.list.selectTotal"}
                values={{nb: totalItems}}
            />
        );
    }
}

export default TotalSelectedActionMessage;
