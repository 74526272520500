import React, {useEffect, useState} from 'react';
import {useFormikContext} from "formik";

import LockFieldButton from "../../LockFieldButton";

// LockProductButton and LockVariationButton have the same functions but not the same rendering
// we should create a single component later
function LockVariationButton() {
    const [notAvailableKeys] = useState([
        '@id', // do not remove this
        '@type', // do not remove this
        'variation', // do not remove this
        'pictures',
        'url',
        'upc',
        'barcode',
        'discountedPriceStartDate',
        'discountedPriceEndDate',
        'status'
    ]);

    const [availableKeys] = useState([
        'externalId',
        'name',
        'sku',
        'price',
        'outletPrice',
        'discountedPrice',
        'ecoTax',
        'stock',
        'weight'
    ]);

    const [isEveryFieldLocked, setIsEveryFieldLocked] = useState(false);

    const {values, setFieldValue} = useFormikContext();

    // when a lock field value change
    useEffect(() => {
        if (!values.variationLock) {
            setIsEveryFieldLocked(false);
            return;
        }

        // make a copy to not directly delete form values
        const variationLockValues = structuredClone(values.variationLock);

        // remove key that not exist in the form
        notAvailableKeys.forEach(key => {
            delete variationLockValues[key];
        });

        const valueHasEveryAvailableKeys = availableKeys.every(key => variationLockValues.hasOwnProperty(key));

        if (valueHasEveryAvailableKeys) {
            const isEveryFieldLocked = Object.values(variationLockValues).every((lock) => lock === true);

            setIsEveryFieldLocked(isEveryFieldLocked);
        }
    }, [values.variationLock])

    function updateAllLockFields(isLock) {
        let allLockedFields = {};

        if (values.variationLock) {
            const variationLockValues = structuredClone(values.variationLock);

            // remove notAvailableKeys from values to change
            const filtered = Object.entries(variationLockValues).filter(([key]) => !notAvailableKeys.includes(key))

            filtered.forEach(([key]) => {
                variationLockValues[key] = isLock
            });

            allLockedFields = variationLockValues;
        } else {
            availableKeys.forEach((key) => {
                allLockedFields[key] = isLock
            })
        }

        values.stockInventories.forEach((stockInventory, index) => {
            stockInventory.locked = isLock;
        })

        setFieldValue('variationLock', allLockedFields)
    }

    return (
        <LockFieldButton
            isLocked={isEveryFieldLocked}
            onClick={() => updateAllLockFields(!isEveryFieldLocked)}
        />
    );
}

export default LockVariationButton;
