import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage} from "react-intl";
import Select from "react-select";

// @mui/material components
import {Switch} from "@mui/material";
import {withStyles} from "@mui/styles";

// core components
import Input from "components/input/input";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ActionsContainer from "components/form/ActionsContainer";
import CancelButton from "../components/CancelButton";
import SubmitButton from "../components/SubmitButton";

// styles
import formStyle from "assets/jss/views/feed/formStyle";

// utils
import {DisableVariationsFeedOptions} from "utils/DisableVariationsFeedOptions";
import {FeedType} from "../../../../../utils/FeedType";

class Form extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    static apiTypes = [
        {
            value: 'prestashop',
            label: 'Prestashop',
            keyFieldName: 'key',
            secretFieldName: null,
            idShop: 'id_shop',
        },
        {
            value: 'shopify',
            label: 'Shopify',
            keyFieldName: 'api_key',
            secretFieldName: 'password',
            accessFieldName: 'access_token',
            locationId: 'location_id',
            allowDraft: 'allow_draft',
            onlyActive: 'only_active',
        },
        {
            value: 'woocommerce',
            label: 'Woocommerce',
            keyFieldName: 'consumerKey',
            secretFieldName: 'consumerSecret',
        },
        {
            value: 'wix',
            label: 'Wix',
            keyFieldName: null,
            secretFieldName: null,
        },
        {
            value: 'magento',
            label: 'Magento',
            keyFieldName: 'api_key',
            secretFieldName: 'password',
        },
        {
            value: 'instore',
            label: 'InStore',
            keyFieldName: null,
            secretFieldName: null,
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            recurrenceChecked: false,
            apiType: null,
            mappedLocales: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.initialValues === null && nextProps.initialValues !== null)) {
            const newState = {};
            if (nextProps.initialValues.recurrence > 0) {
                newState.recurrenceChecked = true;
            }

            if (nextProps.initialValues.dataType) {
                const apiTypeItem = Form.apiTypes.find(type => type.value === nextProps.initialValues.dataType);
                newState.apiType = !!apiTypeItem ? apiTypeItem : null;
            }

            this.setState(newState);
        }
    }

    renderField = data => {
        const {classes} = this.props;
        const filedName = data.input.name;

        return (
            <div className={classes.fieldContainer}>
                <label
                    htmlFor={`apiFeed_${data.input.name}`}
                    className={classes.fieldLabel}
                >
                    {data.label}
                </label>
                {data.type === "checkbox" &&
                <input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    placeholder={data.placeholder}
                    id={`apiFeed_${data.input.name}`}
                    className={classes.fieldFloatRight}
                />
                }
                {(data.options && data.type === "select") &&
                <Select
                    {...data.input}
                    options={data.options}
                    multiple={data.hasOwnProperty('multiple') ? data.multiple : false}
                    value={data.selectedValue || data.options.find(option => option.value === data.input.value)}
                    defaultValue={data.defaultValue}
                    required={data.required}
                    placeholder={data.placeholder}
                    id={`apiFeed_${data.input.name}`}
                >
                </Select>
                }
                {(data.type === 'radio' && (
                    <input
                        type={data.type}
                        id={`apiFeed_${data.input.name}`}
                        {...data.input}
                    />
                ))}
                {(data.type !== "select" && data.type !== "checkbox" && data.type !== 'radio') &&
                <Input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    id={`apiFeed_${data.input.name}`}
                    inputRootCustomClasses={classes.input}
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
                }
                {this.props.createError && this.props.createError.errors && this.props.createError.errors[filedName] && <span className={classes.errorMessages}><FormattedMessage id={this.props.createError.errors[filedName]}/></span>}
                {this.props.updateError && this.props.updateError.errors && this.props.updateError.errors[filedName] && <span className={classes.errorMessages}><FormattedMessage id={this.props.updateError.errors[filedName]}/></span>}
            </div>
        );
    };

    handleSubmit = (e) => {
        const {fields} = this.props;
        const {apiType, recurrenceUnit} = this.state;
        if (!apiType) {
            return;
        }

        const {configuration} = fields.values;
        const newConfig = {
            url: configuration.url,
        };
        if (!!configuration[apiType.keyFieldName]) {
            newConfig[apiType.keyFieldName] = configuration[apiType.keyFieldName];
        }
        if (!!configuration[apiType.secretFieldName]) {
            newConfig[apiType.secretFieldName] = configuration[apiType.secretFieldName];
        }
        if (!!configuration[apiType.accessFieldName]) {
            newConfig[apiType.accessFieldName] = configuration[apiType.accessFieldName];
        }
        if (!!configuration[apiType.locationId]) {
            newConfig[apiType.locationId] = configuration[apiType.locationId];
        }
        if (!!configuration[apiType.idShop]) {
            newConfig[apiType.idShop] = configuration[apiType.idShop];
        }
        if (!!configuration[apiType.allowDraft]) {
            newConfig[apiType.allowDraft] = configuration[apiType.allowDraft];
        }
        if (!!configuration[apiType.onlyActive]) {
            newConfig[apiType.onlyActive] = configuration[apiType.onlyActive];
        }
        if (!!configuration['mappedLocales']) {
            if (Object.keys(this.state.mappedLocales)) {
                newConfig['mappedLocales'] = {...configuration['mappedLocales'], ...this.state.mappedLocales};
            } else {
                newConfig['mappedLocales'] = configuration['mappedLocales'];
            }
        }

        if (!!configuration['locales']) {
            newConfig['locales'] = configuration['locales'];
        }

        fields.values.configuration = newConfig;

        e.preventDefault();

        fields.values.transportType = 'api';
        fields.values.dataType = apiType.value;
        if (fields.values) {
            if (this.state.recurrenceChecked === true) {
                fields.values.recurrenceUnit = recurrenceUnit;
            } else {
                fields.values.recurrence = 0;
                fields.values.recurrenceUnit = "min";
            }
        }

        this.props.handleSubmit();
    };

    handleLocaleChange = (value, locale) => {
        let newMappedLocales = this.state.mappedLocales;
        newMappedLocales[locale] = value.value;
        this.setState({mappedLocales: newMappedLocales});
    }

    handleRecurrenceChange = () => {
        if (this.state.recurrenceChecked === true) {
            this.setState({recurrenceUnit: undefined});
        } else {
            this.setState({recurrenceUnit: 'min'});
        }

        this.setState({recurrenceChecked: !this.state.recurrenceChecked});
    };

    render() {
        const {classes, locales, fields, edit} = this.props;
        const {apiType} = this.state;

        const apiTypeConfig = Form.apiTypes.find(apiTypeConfig => apiTypeConfig.value === apiType?.value);
        const configLocales = edit && fields && fields.values ? fields.values.configuration.locales : null;
        const mappedLocales = edit && fields && fields.values ? fields.values.configuration.mappedLocales : null;

        const parse = value => (value === "" || value < 0) ? 0 : parseInt(value);

        const renderLocaleMapper = () => {
            return edit && configLocales && locales && locales.map((locale, key) => {
                const mappedLocale = mappedLocales && mappedLocales[locale];
                return (
                    <GridItem xs={12} sm={6} key={key}>
                        <Field
                            component={this.renderField}
                            name={'configuration[mappedLocales]['+locale+']'}
                            options={configLocales}
                            label={locale}
                            type={"select"}
                            onChange={value => this.handleLocaleChange(value, locale)}
                            selectedValue={
                                this.state.mappedLocales[locale] ??
                                mappedLocale ? configLocales.filter(function(item){ return mappedLocale === item['value']})[0] : null
                            }
                        />
                    </GridItem>
                );
            });
        }

        const recurrenceUnitOptions = [{label: 'Minutes', value: 'min'}, {label: 'Heures', value: 'hour'}, {label: 'Jours', value: 'day'}]

        return (
            <form onSubmit={this.handleSubmit}>
                <GridContainer className={this.props.classes.formContainer}>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"name"}
                            label={<FormattedMessage id={"feed.form.label.name"}/>}
                            type={"text"}
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"dataType"}
                            options={Form.apiTypes}
                            label={<FormattedMessage id={"feed.form.label.api_type"}/>}
                            type={"select"}
                            onChange={value => this.setState({apiType: value})}
                            selectedValue={apiType}
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Field
                            component={this.renderField}
                            name={"configuration[url]"}
                            label={<FormattedMessage id={"feed.form.label.url"}/>}
                            type={"text"}
                            required
                        />
                        <Field
                            name="disableVariationsNotSeen"
                            component={this.renderField}
                            type="radio"
                            value={DisableVariationsFeedOptions.ALL}
                            label={<FormattedMessage id={"feed.form.label.disable.not.seen.all.feeds"} />}
                        />
                        {/*<Field
                            name="disableVariationsNotSeen"
                            component={this.renderField}
                            type="radio"
                            value={DisableVariationsFeedOptions.THIS}
                            label={<FormattedMessage id={"feed.form.label.disable.not.seen.this.feed"} />}
                        />*/}
                        <Field
                            name="disableVariationsNotSeen"
                            component={this.renderField}
                            type="radio"
                            value={DisableVariationsFeedOptions.NONE}
                            label={<FormattedMessage id={"feed.form.label.disable.not.seen.none"}/>}
                        />
                        <Field
                            type={"select"}
                            component={this.renderField}
                            defaultValue={{"label": <FormattedMessage id={`feed.form.label.type.${FeedType.MERCH}`} />, "value": FeedType.MERCH}}
                            options={[
                                {"label": <FormattedMessage id={`feed.form.label.type.${FeedType.MERCH}`} />, "value":FeedType.MERCH},
                                {"label": <FormattedMessage id={`feed.form.label.type.${FeedType.STOCK}`} />, "value": FeedType.STOCK}
                            ]}
                            name={"type"}
                            label={<FormattedMessage id={"feed.form.label.type"}/>}
                            required
                        />
                    </GridItem>
                    {apiTypeConfig &&
                    <GridItem xs={12} sm={6}>
                        {!!apiTypeConfig.keyFieldName &&
                            <Field
                                component={this.renderField}
                                name={`configuration['${apiTypeConfig.keyFieldName}']`}
                                label={<FormattedMessage id={`feed.form.label.api_key.${apiTypeConfig.value}`}/>}
                                type={"text"}
                                required={ !apiTypeConfig.accessFieldName }
                            />
                        }
                        {!!apiTypeConfig.secretFieldName &&
                            <Field
                                component={this.renderField}
                                name={`configuration['${apiTypeConfig.secretFieldName}']`}
                                label={<FormattedMessage id={`feed.form.label.api_secret.${apiTypeConfig.value}`}/>}
                                type={"text"}
                                required={ !apiTypeConfig.accessFieldName }
                            />
                        }
                        {!!apiTypeConfig.accessFieldName &&
                            <Field
                                component={this.renderField}
                                name={`configuration['${apiTypeConfig.accessFieldName}']`}
                                label={<FormattedMessage id={`feed.form.label.access_token.${apiTypeConfig.value}`}/>}
                                type={"text"}
                                required={ !apiTypeConfig.secretFieldName && !apiTypeConfig.keyFieldName  }
                            />
                        }
                        {!!apiTypeConfig.locationId &&
                            <Field
                                component={this.renderField}
                                name={`configuration['${apiTypeConfig.locationId}']`}
                                label={<FormattedMessage id={`feed.form.label.location_id.${apiTypeConfig.value}`}/>}
                                type={"text"}
                                required={ !apiTypeConfig.secretFieldName && !apiTypeConfig.keyFieldName  }
                            />
                        }
                        {!!apiTypeConfig.idShop &&
                            <Field
                                component={this.renderField}
                                name={`configuration['${apiTypeConfig.idShop}']`}
                                label={<FormattedMessage id={`feed.form.label.id_shop.${apiTypeConfig.value}`}/>}
                                type={"text"}
                                required={ !apiTypeConfig.secretFieldName && !apiTypeConfig.keyFieldName  }
                            />
                        }
                        {!!apiTypeConfig.allowDraft &&
                            <Field
                                component={this.renderField}
                                name={`configuration['${apiTypeConfig.allowDraft}']`}
                                label={<FormattedMessage id={`feed.form.label.allow_draft.${apiTypeConfig.value}`}/>}
                                type={"checkbox"}
                            />
                        }
                        {!!apiTypeConfig.onlyActive &&
                            <Field
                                component={this.renderField}
                                name={`configuration['${apiTypeConfig.onlyActive}']`}
                                label={<FormattedMessage id={`feed.form.label.only_active.${apiTypeConfig.value}`}/>}
                                type={"checkbox"}
                            />
                        }
                    </GridItem>}

                    <GridItem xs={12} sm={6}>
                        <div className={classes.recurrence}>
                            <label className={classes.fieldLabel}>
                                <FormattedMessage id={"feed.form.label.recurrence"}/>
                            </label>
                            <Switch
                                checked={this.state.recurrenceChecked}
                                onChange={this.handleRecurrenceChange}
                                color="primary"
                            />
                        </div>
                        {this.state.recurrenceChecked &&
                        <div>
                            <Field
                                component={this.renderField}
                                name={"recurrence"}
                                type={"number"}
                                parse={parse}
                                required
                            />
                            <Field
                                component={this.renderField}
                                name={"recurrenceUnit"}
                                label={<FormattedMessage id={"feed.form.label.recurrenceUnit"}/>}
                                type={"select"}
                                selected={this.state.recurrenceUnit}
                                defaultValue={{label: 'Minutes', value: 'min'}}
                                onChange={(item) => this.setState({recurrenceUnit: item.value})}
                                options={recurrenceUnitOptions}
                                required
                            />
                            </div>
                        }
                        <div>
                            <Field
                                component={this.renderField}
                                label={<FormattedMessage id={"feed.form.label.skipRule"} />}
                                name={"skipRule"}
                                type={"text"}
                            />
                        </div>
                    </GridItem>

                    {(apiTypeConfig && configLocales && locales && apiTypeConfig.value === 'prestashop') &&
                        <GridItem xs={12} sm={12}>
                            <div className={classes.locales}>
                                <label className={classes.fieldLabel}>
                                    <FormattedMessage id={"feed.form.label.locales"}/>
                                </label>
                            </div>
                            <div>
                                {renderLocaleMapper()}
                            </div>
                        </GridItem>
                    }
                </GridContainer>
                <ActionsContainer>
                    <CancelButton retailerId={this.props.retailer && this.props.retailer['@id']} />
                    <SubmitButton loading={this.props.createLoading || this.props.updateLoading} />
                </ActionsContainer>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        createLoading: state.feed.create.loading,
        createError: state.feed.create.error,
        updateError: state.feed.update.updateError,
        updateLoading:state.feed.update.updateLoading,
        fields: state.form.apiFeed,
        retailer: state.retailer.show.retrieved
    };
};

export default reduxForm({
    form: "apiFeed",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
}, mapStateToProps)(connect(
    mapStateToProps,
    null
)(withStyles(formStyle)(Form)));
