import {fetch, normalize} from "utils/dataAccess";

export function createStockInventory(variation, stockInventory) {
    let stockToCreate = {
        variation: variation["@id"],
        inventory: stockInventory[0].inventory["@id"],
        stock: parseInt(stockInventory[0].stock),
    }

    return new Promise((resolve, reject) => {
        fetch('/stock_inventories', {
            method: "POST",
            body: JSON.stringify(stockToCreate),
        }).then(response => response.json()).then(async (retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    })
}