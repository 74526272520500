import {fetch} from "utils/dataAccess";
import normalizeTranslations from "actions/translations";
import persist from "./persister";
import {convertMediaObjectsToVariationMediaObjects} from "./variationMediaObject";

export default async function update(variation, values)  {
    // for all new pictures, we need to create his variation_media_objects and add it to variation
    // before update the variation (important)
    values.pictures = await convertMediaObjectsToVariationMediaObjects(variation['@id'], values.pictures)

    values = persist(values, variation['@id']);

    values = normalizeTranslations(values);

    const response = await fetch(`/variations/${variation.id}`, {
        method: "PUT",
        body: JSON.stringify(values),
    });

    return await response.json();
}
