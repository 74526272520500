import React from 'react';

import {Grid} from "@mui/material";

import Label from "./Label";
import {FormattedMessage} from "react-intl";
import InputTextField from "./InputTextField";

function ColorPickerField(props) {
    return (
        <>
            <Label htmlFor={props.name}>
                <FormattedMessage id={props.label ?? "colorPicker.color.label"} />
            </Label>
            <Grid container alignItems="center">
                <Grid item>
                    <InputTextField
                        name={props.name}
                        type="color"
                        onChange={props.handleChange}
                        value={props.value ?? props.defaultValue ?? '#ffffff'}
                        fullWidth
                        sx={{
                            width: '50px',
                            height: '50px',
                            padding: '0',
                            border: 'none',
                            marginRight: '10px',
                            '& .MuiInputBase-root': {
                                position: 'relative',
                                height: '100%',
                                overflow: 'hidden',
                                '& input': {
                                    padding: '0 !important',
                                    height: '120%',
                                    width: '120%',
                                    cursor: 'pointer',
                                    '&::-webkit-color-swatch-wrapper': {
                                        padding: '0',
                                        borderRadius: '0',
                                    },
                                    '&::-moz-color-swatch': {
                                        border: 'none',
                                    },
                                    '&::-webkit-color-swatch': {
                                        border: 'none',
                                    }
                                },
                                '& fieldset': {
                                    padding: '0',
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <InputTextField
                        name={props.name}
                        value={props.value}
                        placeholder={props.intl.formatMessage({id: props.placeholder ?? 'colorPicker.color.placeholder'})}
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default ColorPickerField;
