import React from "react";
import {getTranslation} from "domain/helpers/translations";
import {FormattedMessage} from "react-intl";

export function getFilterLabel(item, asString) {

    if (item['@type'] === 'Retailer' || item['@type'] === 'Brand' || item['@type'] === 'Channel') {
        return item.name;
    }

    if (!item.translations) {
        return asString ? item.name : <FormattedMessage id={"merchandising.show.label.filters." + item.name} />;
    }

    const translations = getTranslation(item);
    if (Object.hasOwn(translations, 'name')) {
        return translations.name;
    }

    if (Object.hasOwn(translations, 'value')) {
        return translations.value;
    }

    throw Error('Could not find a valid translation for filter ' + item.id);
}
