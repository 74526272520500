import React, {useContext, useEffect, useState} from 'react';
import {Draggable} from "react-beautiful-dnd";
import { FixedSizeList} from 'react-window';

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

import DragAndDropContext from "components/dragAndDrop/DragAndDropContext";
import DeleteDialog from "components/List/components/DeleteDialog";

import CreateBelow from "../../../configuration/components/CreateBelow";
import Form from "./components/Form";
import Header from "./components/Header";
import ItemLine from "./components/ItemLine";

import {updatePosition} from "api/optionValues/updatePosition";
import listByOptionId from "api/optionValues/list";
import deleteOptionValue from "api/optionValues/delete";

import {ListContext} from "components/List/context/ListContext";

function Content({option}) {
    const [lastPosition, setLastPosition] = useState(0);

    const [isElementBeingDragged, setIsElementBeingDragged] = useState(false);

    const {
        loading,
        setLoading,
        listItems,
        setListItems,
        selectedItem,
        setSelectedItem,
        setNotFilteredListItems,
        createBelow,
        showCreateBelow,
        hideCreateBelow,
        openCreateDialogViaCreateBelow,
        isCreateDialogOpen,
        closeCreateDialog,
        closeDeleteDialog,
        showErrorNotification,
        moveListItem,
    } = useContext(ListContext)

    // when the option id in the url change
    useEffect(() => {
        getOptionValues()
    },[option])

    function getOptionValues() {
        closeCreateDialog();
        closeDeleteDialog();

        setSelectedItem(null);

        setLoading(true);

        listByOptionId(option.id).then((response) => {
            setListItems(response);
            setNotFilteredListItems(response);

            if (response.length > 0) {
                setLastPosition(response[response.length - 1].position)
            }
        }).catch(() => {
            showErrorNotification();
        }).finally(() => {
            setLoading(false);
        })
    }

    function moveOptionValue(result) {
        if (!result.destination) {
            return;
        }

        setIsElementBeingDragged(false);

        // update on front
        moveListItem(result)

        // update on back
        const destinationOptionPosition = listItems[result.destination.index]?.position;

        if (!destinationOptionPosition) {
            return;
        }

        setLoading(true);

        updatePosition(result.draggableId, destinationOptionPosition)
            .then(() => getOptionValues())
            .catch(() => showErrorNotification())
            .finally(() => setLoading(false))
        ;
    }

    function handleDeleteOptionValue() {
        setLoading(true);

        deleteOptionValue(selectedItem).then(() => {
            getOptionValues()
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Grid container>
            <Header />
            <Grid item container>
                <DragAndDropContext
                    onDragEnd={(result) => moveOptionValue(result)}
                    onDragStart={() => setIsElementBeingDragged(true)}
                    droppableId={"optionValueList"}
                    items={listItems}
                    renderClone={(data) => <ItemLine optionValue={data} />}
                >
                    {droppableProvided => (
                        <FixedSizeList
                            height={window.innerHeight * 0.79}
                            itemCount={listItems.length}
                            itemSize={58}
                            width={'100%'}
                            itemData={listItems}
                            innerRef={droppableProvided.innerRef}
                        >
                            {({data, index, style}) => {
                                const optionValue = data[index];

                                if (!optionValue) {
                                    return null;
                                }

                                return (
                                    <Grid
                                        item
                                        container
                                        key={optionValue.id}
                                        onMouseEnter={(event) => {
                                            !isElementBeingDragged && showCreateBelow(event, optionValue.id)
                                        }}
                                        onMouseLeave={() => !isElementBeingDragged && hideCreateBelow()}
                                        sx={{...style, padding: '12px 0'}}
                                    >
                                        <Draggable
                                            draggableId={optionValue.id}
                                            index={index}
                                            isDragDisabled={loading}
                                        >
                                            {(provided, snapshot) => (
                                                <ItemLine
                                                    optionValue={optionValue}
                                                    isDragging={snapshot.isDragging}
                                                    innerRef={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                />
                                            )}
                                        </Draggable>
                                        <CreateBelow
                                            isVisible={optionValue.id === createBelow.listItemId}
                                            onClick={() => openCreateDialogViaCreateBelow(optionValue.position)}
                                            style={{ bottom: '-28px' }}
                                        />
                                    </Grid>
                                )
                            }}
                        </FixedSizeList>
                    )}
                </DragAndDropContext>
            </Grid>
            <Dialog
                open={isCreateDialogOpen}
                onClose={() => closeCreateDialog()}
                maxWidth={'sm'}
                scroll={'body'}
            >
                <DialogContent>
                    <Form
                        refreshList={getOptionValues}
                        selectedOptionValue={selectedItem}
                        createBelow={createBelow}
                        lastPosition={lastPosition}
                        option={option}
                    />
                </DialogContent>
            </Dialog>
            <DeleteDialog
                titleId={"optionValues.delete.title"}
                contentTextId={"optionValues.delete.content"}
                onDeleteClick={() => handleDeleteOptionValue()}
            />
        </Grid>
    );
}

export default Content;
