import React, {useEffect, useState} from 'react';
import store from "store";
import {Redirect} from "react-router-dom";

import List from "components/List";

import Content from "./components/Content";

import list from "api/option/list";

import {Authorizations, hasAuthorization} from "utils/authorizations";

import {getTranslation} from "domain/helpers/translations";

function Index(props) {
    const authentication = store.getState().authentication;
    const {authorizations} = authentication;

    const optionId = props.match.params.id;

    const [optionName, setOptionName] = useState(null);
    const [option, setOption] = useState(null);

    // componentDidMount
    useEffect(() => {
        if (false === hasAuthorization(authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT)) {
            return <Redirect to={'/'} />;
        }

        getOptionById(optionId);
    }, []);

    // when the option id in the url change
    useEffect(() => {
        getOptionById(optionId);
    },[optionId])

    function getOptionById(optionId) {
        list(`id=${optionId}`).then((response) => {
            if (!response) {
                return <Redirect to={'/'} />;
            }

            setOptionName(getTranslation(response[0]).name);
            setOption(response[0]);
        })
    }

    if (!optionName) return null;

    return (
        <List title={optionName}>
            {/* to use ListContext we need to move content into another component :( */}
            <Content option={option}/>
        </List>
    );
}

export default Index;
