import store from "../store";

// this is for formik initialValues with translatable entities
export default function populateMissingTranslations(retailerTranslations) {
    const locales = store.getState().currentOrganization?.retrieved?.locales;

    if (!locales) {
        return retailerTranslations
    }

    locales.forEach((locale) => {
        if (!(locale in retailerTranslations)) {
            retailerTranslations[locale] = {
                locale: locale
            }
        }

    })
    return retailerTranslations;
}