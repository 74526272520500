import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../LockFieldButton";
import {Grid} from "@mui/material";

function StockField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <Grid item lg={6} md={12}>
            <Label required htmlFor='stock'>
                <FormattedMessage id='product.show.variation.form.field.stock' />
            </Label>
            <InputTextField
                name={`stockInventories[${props.index}].stock`}
                onChange={handleChange}
                value={props.stock}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.stock'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.stockInventories?.[props.index]?.stock && Boolean(errors.stockInventories?.[props.index]?.stock)}
                helperText={touched.stockInventories?.[props.index]?.stock && errors.stockInventories?.[props.index]?.stock}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                name={`stockInventories[${props.index}].locked`}
                                isLocked={values.stockInventories?.[props.index]?.locked}
                                onClick={() => setFieldValue(`stockInventories[${props.index}].locked`, !values.stockInventories?.[props.index]?.locked)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    );
}

export default injectIntl(StockField);
