import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {Formik, Form as FormikForm} from "formik";

import Grid from "@mui/material/Grid";

import CustomSwitch from "components/switch/CustomSwitch";

import CatalogSettingsFields from "./components/CatalogSettingsFields";
import PreferencesActions from "./components/PreferencesActions";

import {ProductShowContext} from "../../context/ProductShowContext";

import {updatePatch} from "api/product/update";
import ChannelsField from "./components/ChannelsField";

ProductPreferences.propTypes = {
    product: PropTypes.shape({
        privateProduct: PropTypes.bool,
        privatePrice: PropTypes.bool,
        catalogOnly: PropTypes.bool,
        hiddenPrice: PropTypes.bool,
        status: PropTypes.bool
    })
};

function ProductPreferences({product}) {
    const {refreshProduct} = useContext(ProductShowContext);

    const initialValues = {
        'channels': product.channels || [],
        'privateProduct': !!product.privateProduct,
        'privatePrice': !!product.privatePrice,
        'catalogOnly': !!product.catalogOnly,
        'hiddenPrice': !!product.hiddenPrice,
        'status': !!product.status
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => updatePatch(product.id, values).then(() => refreshProduct())}
        >
            {({
                  values,
                  handleChange,
            }) => (
                <FormikForm>
                    <Grid container>
                        <Grid
                            item
                            container
                            rowSpacing={2}
                            sx={{
                                fontSize: '14px',
                                fontWeight: 400
                            }}
                        >
                            <Grid item xs={12}>
                                <ChannelsField />
                            </Grid>
                            <CatalogSettingsFields />
                            <Grid item xs={12}>
                                <CustomSwitch
                                    name='status'
                                    checked={values.status}
                                    onClick={handleChange}
                                />
                                <FormattedMessage id='product.show.preferences.status' />
                            </Grid>
                            <PreferencesActions />
                        </Grid>
                    </Grid>
                </FormikForm>
            )}
        </Formik>
    );
}

export default ProductPreferences;
