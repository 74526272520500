import React, {useContext, useEffect, useState} from "react";

import {Tooltip, useMediaQuery} from "@mui/material";
import {ErrorOutline} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

import {parseErrors} from "../../../../../../../../../utils/validationErrorParser";
import PropTypes from "prop-types";
import {UserAgentUtils} from "../../../../../../../../../utils/useragent";

VariationTooltip.propTypes = {
    errors: PropTypes.string
}

export default function VariationTooltip({errors}) {
    const [open, setOpen] = useState(false)

    const selectecVariation = useContext(ProductShowContext);

    const isMobile = UserAgentUtils.isMobile()

    const handleTooltipClose = (event) => {
        event.stopPropagation();
        setOpen(false);
    };

    const handleTooltipOpen = (event) => {
        event.stopPropagation();
        setOpen(true);
    };

    const handleClickOnTooltip = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        setOpen(false)
    }, [selectecVariation]);

    return (
        <div onClick={event => handleClickOnTooltip(event)}>
            {isMobile ?
                <Tooltip
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    slotProps={{
                        popper: {
                            disablePortal: true,
                        },
                    }}
                    title={
                        <div style={{display: "flex", width: "100%"}}>
                            <div>
                                {parseErrors(errors).map((error, index) => (
                                    <li style={{listStyle: 'none'}} key={index}>
                                        {error}
                                    </li>
                                ))}
                            </div>
                            <CloseIcon
                                style={{maxWidth: '8%'}}
                                onClick={handleTooltipClose}
                            />
                        </div>
                    }
                >
                    <ErrorOutline
                        onClick={handleTooltipOpen}
                        fontSize={"small"}
                        color={"error"}
                        style={{
                            position: 'absolute',
                            zIndex: '1',
                            marginLeft: '-25px',
                            marginTop: '10px'
                        }}
                    />
                </Tooltip>
                :
                <Tooltip title={parseErrors(errors).map((error, index) => (
                    <li style={{listStyle: 'none'}} key={index}>
                        {error}
                    </li>
                ))}
                >
                    <ErrorOutline
                        fontSize={"small"}
                        color={"error"}
                        style={{
                            position: 'absolute',
                            zIndex: '1',
                            marginLeft: '-25px',
                            marginTop: '10px'
                        }}
                    />
                </Tooltip>
            }
        </div>
    )
}