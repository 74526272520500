import {createContext} from "react";

export const ProductShowContext = createContext({
    loading: false,
    setLoading: () => {},

    refreshProduct: () => {},

    genderList: [],
    brandList: [],
    taxRateList: [],
    categoryList: [],
    cornerList: [],
    retailerOptionsValueList: {},
    attributeList: [],
    attributeLoading: true,

    product: null,
    selectedVariation: null,
    switchVariation: () => {},
    refreshSelectedVariationFormValues: () => {},
    variantsCombinaisons: [],

    orderFormattedSelectOptionsByLabel: () => {},

    currentRetailer: null,
    isStockEasyUser: false,
    userAuthorizations: [],

    optionsCount: 0,

    cantEditProduct: false
})
