import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";

function PeriodField() {
    const {values, setFieldValue} = useFormikContext();

    const [years, setYears] = useState([]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();

        const years = [];

        for (let i = 2024; i <= currentYear; i++) {
            years.push(i);
        }

        setYears(years);
    }, [])

    return (
        <>
            <SelectTitle required={true} titleTranslationKey='compliance.dac7.form.field.period'/>
            <RadioGroup
                alignListWithButton
                selectedValueLabel={values.period ?? ''}
                name={'period'}
                value={values.period}
                onChange={(e) => setFieldValue("period", Number(e.target.value))}
            >
                {years.map((year, index) => (
                    <FormControlLabel
                        key={index}
                        value={year}
                        label={year}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default PeriodField;
