// Must be the same as Sylius TagPosition enum
export const TagPosition = {
    Top: 'top',
    Bottom: 'bottom',
};

export const RetailersTagPosition = {
    Top: 'top',
    Bottom: 'bottom',
    MerchantsList: 'brand_list'
}