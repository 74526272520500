import {useContext, useEffect, useState} from "react";
import {StoreContext} from "contexts/storeContext";
import SimpleFilterPanel from "../filter/simpleFilterPanel";

function useChannels(props) {
    const [channelsFilters, setChannelsFilters] = useState(null);
    const [channelsLoaded, setChannelsLoaded] = useState(false);

    const store = useContext(StoreContext);

    let channels = store.getState()?.currentOrganization.retrieved?.eshopConfig?.channels ?? [];

    channels.forEach((channel) => {
        channel['name'] = channel.code;
    });

    useEffect(() => {
        setChannelsFilters(
            {
                "tabTitle": "channels",
                "tabContent": {
                    component: SimpleFilterPanel,
                    items: channels,
                }
            }
        );

        setChannelsLoaded(true);
    }, []);

    return {
        channelsLoaded,
        channelsFilters
    };
}

export default (useChannels);
