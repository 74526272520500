import { v4 as uuidv4 } from 'uuid';

export default function persist(values, variationId = null) {
    // copied from actions/variation/update.js
    // but without @id type
    const data = {};
    const available_data = {
        "product"           : "@id",
        "translations"      : "translations",
        "name"              : "string",
        "externalId"        : "string",
        "outletPrice"       : "float",
        "discountedPrice"   : "float",
        "price"             : "float",
        "ecoTax"            : "float",
        "stock"             : "int",
        "url"               : "string",
        "sku"               : "sku",
        "status"            : "bool",
        "pictures"          : "pictures",
        "unavailableBefore" : "date",
        "barcode"           : "string",
        "weight"            : "float",
        "retailerOptionValues" : "retailerOptionValues",
        "stockInventories"    : "stockInventories",
    };

    Object.keys(available_data).forEach(function(key,index) {
        if (undefined === values[key]) {
            return;
        }

        switch (available_data[key]) {
            default: break;
            case "string":
                data[key] = (values[key])? values[key] : null;
                break;
            case "float":
                if (values[key]) {
                    if (typeof values[key] === 'string') {
                        values[key] = values[key].replace(',', '.');
                    }
                    values[key] = parseFloat(values[key]);
                } else {
                    values[key] = null;
                }
                data[key] = values[key];
                break;
            case "int":
                data[key] = (values[key])? parseInt(values[key]) : 0;
                break;
            case "bool":
                data[key] = (values[key] !== null)? values[key] : false;
                break;
            case "array":
                data[key] = values[key];
                break;
            case "pictures":
                if (Array.isArray(values[key]) || typeof values[key] === "object") {
                    data[key] = values[key].map(function (value, index) {
                        return {"@id": value["@id"], "position": index};
                    });
                }
                break;
            case "date":
                if (values[key]) {
                    if (values[key] instanceof Date) {
                        data[key] = values[key];
                    } else if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(values[key])) {
                        data[key] = new Date(values[key]);
                    } else {
                        data[key] = null;
                    }
                } else {
                    data[key] = null;
                }
                break;
            case "sku":
                data[key] = values[key] || uuidv4();
                break;
            case "@id":
                data[key] = values[key];
                break;
            case "retailerOptionValues":
                if (Array.isArray(values[key])) {
                    data[key] = values[key].map((value) => {
                        const uuid = value['@id'] ?? `/retailer_option_values/${value.id}`
                        return {"@id": uuid};
                    });
                }
                break;
            case "translations":
                data[key] = values[key]
                break;
            case "stockInventories":
                if (null !== variationId) {
                    if (Array.isArray(values[key])) {
                        data[key] = values[key].map((value) => {
                            if (undefined === value["@id"]) {
                                return {
                                    "variation": variationId,
                                    "inventory": value.inventory["@id"],
                                    "locked": value.locked,
                                    "stock": parseInt(value.stock),
                                };
                            }
                            return {
                                "@id": value["@id"],
                                "stock": parseInt(value.stock),
                                "locked": value.locked,
                            };
                        });
                    }
                }
        }
    });

    return data;
}
