import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {PropagateLoader} from 'react-spinners';

// @mui/material components
import {withStyles} from "@mui/styles";

// @mui/icons-material
import {NotificationsActive} from "@mui/icons-material";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Form from "./form";
import Snackbar from "components/snackbar/snackbar";

// styles
import updateStyle from "assets/jss/views/feed/updateStyle";
import {primaryColor} from "assets/jss/main";

// actions
import {retrieve, update, reset} from "actions/feed/mapper/update";
import {FeedType} from "../../../../../utils/FeedType";
import StockForm from "./StockForm";

class Update extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        retrieveLoading: PropTypes.bool.isRequired,
        retrieveError: PropTypes.string,
        updateError: PropTypes.string,
        deleteError: PropTypes.string,
        updated: PropTypes.object,
        deleted: PropTypes.object,
        retrieve: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            retrieveErrorNotification: true,
            updatedNotification: true,
            createdNotification: true,
            updateErrorNotification: true,
            deleteErrorNotification: true,
        };
    }

    componentDidMount() {
        this.props.retrieve(decodeURIComponent(this.props.match.params.mid));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({retrieveErrorNotification: true});
        this.setState({updateErrorNotification: true});
        this.setState({updatedNotification: true});
        this.setState({deleteErrorNotification: true});
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        if (this.props.deleted || this.props.updated) return <Redirect to="./../../../.."/>;
        const item = this.props.retrieved;
        const feedRetrieved = this.props.feedRetrieved;

        const {classes} = this.props;

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    {this.props.updated && (
                        <Snackbar
                            open={this.state.updatedNotification}
                            close
                            closeNotification={() => this.setState({updatedNotification: false})}
                            place={"bl"}
                            color={"success"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"mapper.notifications.updated"}
                                                       values={{name: this.props.updated["name"]}}/>}
                        />
                    )}

                    {this.props.updateError && (
                        <Snackbar
                            open={this.state.updateErrorNotification}
                            close
                            closeNotification={() => this.setState({updateErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={this.props.updateError}
                        />
                    )}

                    {this.props.retrieveError && (
                        <Snackbar
                            open={this.state.retrieveErrorNotification}
                            close
                            closeNotification={() => this.setState({retrieveErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={this.props.retrieveError}
                        />
                    )}

                    {this.props.deleteError && (
                        <Snackbar
                            open={this.state.deleteErrorNotification}
                            close
                            closeNotification={() => this.setState({deleteErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={this.props.deleteError}
                        />
                    )}

                    <GridContainer>
                        <GridItem xs={12}>
                            <div className={classes.propagateLoader}>
                                <PropagateLoader
                                    sizeUnit={"px"}
                                    size={16}
                                    color={primaryColor}
                                    loading={this.props.retrieveLoading}
                                />
                            </div>
                            <div color="info" className={classes.cardHeader}>
                                <h3><FormattedMessage id={"mapper.update.title"} values={{name: feedRetrieved && feedRetrieved.name}}/></h3>
                            </div>
                            {feedRetrieved?.type === FeedType.STOCK ? (
                                <StockForm
                                    onSubmit={values => this.props.update(item, values, decodeURIComponent(this.props.match.params.fid), this.props.match.params.type)}
                                    initialValues={item && item}
                                    match={this.props.match}
                                />
                            ) : (
                                <Form
                                    onSubmit={values => this.props.update(item, values, decodeURIComponent(this.props.match.params.fid), this.props.match.params.type)}
                                    initialValues={item && item}
                                    match={this.props.match}
                                />
                            )}
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retrieved: state.mapper.update.retrieved,
    retrieveError: state.mapper.update.retrieveError,
    retrieveLoading: state.mapper.update.retrieveLoading,
    updateError: state.mapper.update.updateError,
    deleteError: state.mapper.del.error,
    deleted: state.mapper.del.deleted,
    updated: state.mapper.update.updated,
    feedRetrieved: state.feed.update.retrieved
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    update: (item, values, fid, type) => dispatch(update(item, values, fid, type)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(updateStyle)(Update));
