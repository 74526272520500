import React, {useState} from 'react';
import {Grid} from "@mui/material";

import FormBloc from "components/form/FormBloc";

import DeclarationCountryField from "./components/DeclarationCountryField";
import DestinationCountryField from "./components/DestinationCountryField";
import OperatorTaxResidence from "./components/OperatorTaxResidence";
import PeriodField from "./components/PeriodField";

function DeclarationSettings(props) {
    const [countryList] = useState([
        'BE',
        'FI',
        'FR',
        'NL',
    ]);

    return (
        <FormBloc
            titleId='compliance.dac7.form.declarationSettings.title'
            subtitleId={'compliance.dac7.form.declarationSettings.subtitle'}
        >
            <Grid container direction={'column'} rowSpacing={2}>
                <Grid item>
                    <PeriodField />
                </Grid>
                <Grid item>
                    <DeclarationCountryField countryList={countryList} />
                </Grid>
                <Grid item>
                    <OperatorTaxResidence />
                </Grid>
                <Grid item>
                    <DestinationCountryField countryList={countryList} />
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default DeclarationSettings;
