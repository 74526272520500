import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import StockField from "./StockField";
import {useFormikContext} from "formik";
import TabPanel from "@mui/lab/TabPanel";
import store from "../../../../../../../../store";
import {useContext, useEffect, useState} from "react";
import FormBloc from "../../../../../../../../components/form/FormBloc";
import {ProductShowContext} from "../../../../context/ProductShowContext";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function renderTabs(stockInventories) {
    return stockInventories?.map((stock, index) => {
        let locale = handleInventoryLocale(stock)
        return <Tab label={stock.inventory.translations[locale]?.name} {...a11yProps(index)} key={index} />
    })
}

function renderCustomTabPanel(stockInventories, value) {
    return stockInventories?.map((stock, index) => (
        <div>
            <CustomTabPanel
                key={index}
                value={value}
                index={index}
            >
                <StockField stock={stock.stock} locked={stock.locked} index={index}/>
            </CustomTabPanel>
        </div>
    ))
}

function handleInventoryLocale(stock) {

    // Trouve le nom de la locale et en commun entre l'utilisateur et l'inventaire ou entre l'organization et l'inventaire et le retoune.
    const userLocale = store.getState().authentication.member.locale
    const organizationDefaultLocale = store.getState().currentOrganization.retrieved.defaultLocale

    if (stock.inventory.translations[userLocale] !== undefined) {
        return userLocale
    }

    if (stock.inventory.translations[organizationDefaultLocale] !== undefined) {
        return organizationDefaultLocale
    }
}

export default function StockInformation() {
    const [value, setValue] = useState(0);

    const {values, handChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();
    const {selectedVariation} = useContext(ProductShowContext)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
            setValue(0)
    }, [selectedVariation]);

    return (
        <FormBloc titleId='product.show.variation.form.stockInformation.title'>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        {renderTabs(values.stockInventories)}
                    </Tabs>
                </Box>
                {renderCustomTabPanel(values.stockInventories, value)}
            </Box>
        </FormBloc>
    );
}
