import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {stockEasyOffer} from "../../../../../domain/enum/offers";

TotalSelectedMessage.propTypes = {
    offer: PropTypes.string.isRequired,
    totalProductSelected: PropTypes.number.isRequired,
    isTotalSelected: PropTypes.bool
};

function TotalSelectedMessage({offer, totalProductSelected, isTotalSelected = false}) {
    let selectedMessage = 'retailer.product.list.selectedProduct';

    if (isTotalSelected) {
        if (offer === stockEasyOffer) {
            selectedMessage = 'retailer.product.list.totalProductSelected.stockeasy';
        } else {
            selectedMessage = 'retailer.product.list.totalProductSelected';
        }
    }

    return (
        <FormattedMessage
            id={selectedMessage}
            values={{nb: totalProductSelected}}
        />
    );
}

export default TotalSelectedMessage;
