import React, { useState, useEffect } from 'react';
import {TextField, Box, MenuItem, Select, Chip, InputLabel, FormControl, Button, Grid, Tooltip} from '@mui/material';
import InputTextField from "../input/new/InputTextField";
import ActionsContainer from "../containers/ActionsContainer";
import {FormattedMessage} from "react-intl";
import SaveButton from "components/button/SaveButton";
import {black500, grey300} from "../../assets/jss/main";

const CustomTagSelect = (attribute) => {
    const [tags, setTags] = useState([]);
    const [tagName, setTagName] = useState('');
    const [tagCode, setTagCode] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [isCodeModified, setIsCodeModified] = useState(false);

    const createSlug = (text) => {
        return text
            .toLowerCase() // met tout en minuscule
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/--+/g, '-')
            .trim();
    };

    useEffect(() => {
        if (attribute.existingTags && attribute.existingTags.length > 0) {
            setTags(attribute.existingTags);
        }
    }, [attribute.existingTags]);

    useEffect(() => {
        if (tagName && !isCodeModified) {
            const sluggedCode = createSlug(tagName);
            setTagCode(sluggedCode);
        }
    }, [tagName, isCodeModified]);

    const handleAddTag = () => {
        if (tagName.trim() && tagCode.trim()) {
            const newTag = { label: tagName, code: tagCode };
            const updatedTags = [...tags, newTag];
            setTags(updatedTags);
            attribute.onChange(updatedTags);
            setTagName('');
            setTagCode('');
            setSelectedTag('');
            setIsCodeModified(false);
        }
    };

    const handleDeleteTag = (index) => {
        const newTags = tags.filter((_, i) => i !== index);
        setTags(newTags);
        attribute.onChange(newTags);
    };

    const handleCodeChange = (e) => {
        setTagCode(e.target.value);
        setIsCodeModified(true);
    };

    return (
        <Grid container style={{marginTop: '10px'}} spacing={1}>
            <Grid item xs={6}>
                <InputTextField
                    label="Valeur de l'attribut"
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '10px' }}
                />
            </Grid>
            <Grid item xs={6}>
                <InputTextField
                    label="Code"
                    value={tagCode}
                    onChange={handleCodeChange}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '10px' }}
                />
            </Grid>

            <Grid item xs={12}>
                <ActionsContainer>
                    <SaveButton
                        type={"submit"}
                        onClick={handleAddTag}
                        disabled={!tagName.trim() || !tagCode.trim()}>
                        Ajouter la valeur
                    </SaveButton>
                </ActionsContainer>
            </Grid>

            <Grid mt={2}>
                {tags.map((tag, index) => (
                    <Tooltip title={`Code: ${tag.code}`} key={index}>
                        <Chip
                            key={index}
                            label={tag.label}
                            onDelete={() => handleDeleteTag(index)}
                            sx={{
                                '&.MuiChip-root': {
                                    background: grey300,
                                    borderRadius: '4px',
                                    padding: '4px',
                                    margin: '5px',
                                    '& .MuiChip-label': {
                                        paddingLeft: '6px',
                                        paddingRight: '0px',
                                    },
                                    '& .MuiChip-deleteIcon': {
                                        padding: 0,
                                        marginLeft: '4px',
                                        marginRight: '4px',
                                        background: 'transparent',
                                        ':hover': {
                                            color: black500,
                                            cursor: 'pointer',
                                        },
                                        '> svg': {
                                            height: '16px',
                                            width: '16px',
                                        }
                                    }
                                }
                            }}
                        />
                    </Tooltip>
                ))}
            </Grid>
        </Grid>
    );
};

export default CustomTagSelect;
