import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {blueColor, red50, red500} from "assets/jss/main";
import eikowImage from 'assets/img/eikow-bis.svg';

DoNotEditBanner.propTypes = {
    isEikowProduct: PropTypes.bool
};

function DoNotEditBanner({isEikowProduct = false}) {
    if (!isEikowProduct) {
        return null;
    }

    function renderMessage() {
        if (isEikowProduct) {
            return (
                <>
                    <FormattedMessage id={'product.show.resume.banner.eikow'} />
                    <a
                        style={{color: blueColor}}
                        href={'https://app.eikow.com/login'}
                        target={'_blank'}
                    >
                        eikow
                    </a>
                </>
            )
        }
    }

    return (
        <div
            style={{
                background: red50,
                color: red500,
                padding: '24px',
                border: `1px solid ${red500}`,
                borderRadius: '8px'
            }}
        >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <b style={{fontSize: '20px'}}>
                        <FormattedMessage id={'product.show.resume.banner.title'} />
                    </b>
                    <div style={{fontWeight: 400}}>
                        {renderMessage()}
                    </div>
                </div>
                {isEikowProduct && (
                    <img src={eikowImage} alt={'eikow'}/>
                )}
            </div>
        </div>
    );
}

export default DoNotEditBanner;
