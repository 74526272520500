import React from 'react';
import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";

import VariationImage from "./components/VariationImage";
import VariationVariant from "./components/VariationVariant";
import VariationSku from "./components/VariationSku";
import VariationStock from "./components/VariationStock";
import VariationTooltip from "./components/VariationTooltip";

VariationResume.propTypes = {
    image: PropTypes.string,
    optionName: PropTypes.string,
    retailerOptionValue: PropTypes.string,
    sku: PropTypes.string,
    stock: PropTypes.number,
    errors: PropTypes.string
};

function VariationResume({image, optionName, retailerOptionValue, sku, stock, errors}) {

    return (
        <Grid container flexWrap={'nowrap'}>
            <Grid
                item
                container
                xs={9}
                columnSpacing={1}
            >
                <Grid item style={{alignItems: 'center'}}>
                    { errors !== '' &&
                        <VariationTooltip errors={errors}/>
                    }
                    <VariationImage image={image}/>
                </Grid>
                <Grid
                    item
                    container
                    xs
                    direction={'column'}
                    rowSpacing={0.5}
                    style={{overflow: 'hidden'}}
                >
                    <Grid item>
                        <VariationVariant
                            optionName={optionName}
                            retailerOptionValue={retailerOptionValue}
                        />
                    </Grid>
                    <Grid item style={{
                        overflow: 'hidden',
                        maxWidth: '-webkit-fill-available',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                        <VariationSku sku={sku}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={3}
                alignContent={'center'}
                justifyContent={'flex-end'}
            >
                <Grid item>
                    <VariationStock stock={stock}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default VariationResume;
