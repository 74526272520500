import {fetch, normalize} from "utils/dataAccess";
import {setMember, setOrganization} from "utils/dataAccessOrganizationHandler";

export function create(values) {
    setOrganization(values);
    setMember(values);

    const preprodEnv = ['preprod', 'sandbox']

    if (!preprodEnv.includes(process.env.REACT_APP_ENVIRONMENT)) {
        values.testing = false;
    }

    return new Promise((resolve, reject) => {
        fetch('/d_a_c7_declarations', {
            method: "POST",
            body: JSON.stringify(values),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}
