import {fetch, normalize} from "utils/dataAccess";

export function update(id, body) {
    return new Promise((resolve, reject) => {
        fetch(`/option_values/${id}`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: new Headers({'Content-Type': 'application/merge-patch+json'})
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error));
    });
}
