import React, { createContext } from 'react';
import useBrands from "views/marketplace/merchandising/hook/useBrands";
import useCategories from "views/marketplace/merchandising/hook/useCategories";
import useCorners from "views/marketplace/merchandising/hook/useCorners";
import useGenders from "views/marketplace/merchandising/hook/useGenders";
import useRetailers from "views/marketplace/merchandising/hook/useRetailers";
import useStatus from "views/marketplace/merchandising/hook/useStatus";
import useOptions from "../views/marketplace/merchandising/hook/useOptions";
import useChannels from "../views/marketplace/merchandising/hook/useChannels";

export const ProductDataContext = createContext([]);

export function ProductDataProvider({ children }) {

    const { optionsLoaded, optionsFilters } = useOptions();
    const { brandsLoaded, brandsFilters } = useBrands();
    const { categoriesLoaded, categoriesFilters } = useCategories();
    const { cornersLoaded, cornersFilters } = useCorners();
    const { gendersLoaded, gendersFilters } = useGenders();
    const { retailersLoaded, retailersFilters } = useRetailers();
    const { statusLoaded, statusFilters } = useStatus();
    const { channelsLoaded, channelsFilters } = useChannels();

    return (
        <ProductDataContext.Provider
            value={{
                optionsLoaded,
                optionsFilters,
                brandsLoaded,
                brandsFilters,
                categoriesLoaded,
                categoriesFilters,
                cornersLoaded,
                cornersFilters,
                gendersLoaded,
                gendersFilters,
                retailersLoaded,
                retailersFilters,
                statusLoaded,
                statusFilters,
                channelsLoaded,
                channelsFilters
            }}>
            {children}
        </ProductDataContext.Provider>
    );
}
