import React, {Component} from "react";
import PropTypes from "prop-types";

// @mui/material components
import {Checkbox, Hidden, TableCell, TableHead, TableRow, TableSortLabel, Tooltip,} from "@mui/material";

// styles
import {withStyles} from "@mui/styles";
import tableStyle from "assets/jss/views/product/tableHeadStyle";
import {UserAgentUtils} from "../../../../../utils/useragent";

class EnhancedTableHead extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    renderCell(row) {
        const {state, classes} = this.props;

        return (
            <TableCell
                key={row.id}
                sortDirection={state[`order[${row.id}]`] !== undefined ? state[`order[${row.id}]`] : false}
                className={classes.primaryTableHeader}
            >
                <Tooltip
                    title={row.label}
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    disableFocusListener
                    disableHoverListener
                >
                    {row.sort ?
                        <TableSortLabel
                            active={state[`order[${row.id}]`] !== undefined}
                            direction={state[`order[${row.id}]`]}
                            onClick={this.createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                        :
                        <TableSortLabel hideSortIcon className={classes.noClick}>
                            {row.label}
                        </TableSortLabel>
                    }
                </Tooltip>
            </TableCell>
        )
    }
    render() {
        const {classes, onSelectAllClick, isSelectAllChecked} = this.props;

        return (
            <TableHead className={classes.tableHeadCell}>
                <TableRow>
                    {!UserAgentUtils.isMobile() && <TableCell>
                        <Checkbox
                            classes={{root: classes.checkbox, checked: classes.checked}}
                            color={"primary"}
                            onChange={onSelectAllClick}
                            disabled={!this.props.isRetrievedFinished}
                            checked={isSelectAllChecked}
                        />
                    </TableCell>}
                    {this.props.tableHead.map(row => {
                        if (row.hideOnMobile) {
                            return (
                                <Hidden smDown key={row.id}>
                                    {this.renderCell(row)}
                                </Hidden>
                            );
                        } else return this.renderCell(row)
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
};

export default (withStyles(tableStyle)(EnhancedTableHead));
