import React from 'react';
import PropTypes from 'prop-types';

import ProductOriginTag from "./ProductOriginTag";

import {capitalize} from "utils/capitalize";

ProductBrandLabel.propTypes = {
    brand: PropTypes.shape({
        name: PropTypes.string
    }),
    isEikowProduct: PropTypes.bool,
    hasVariationFromFeed: PropTypes.bool
};

function ProductBrandLabel({brand, isEikowProduct = false, hasVariationFromFeed = false}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
            {capitalize(brand.name.toLowerCase())}
            <ProductOriginTag
                isEikowProduct={isEikowProduct}
                hasVariationFromFeed={hasVariationFromFeed}
            />
        </div>
    );
}

export default ProductBrandLabel;
