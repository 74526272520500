import React, {Component} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {PropagateLoader} from 'react-spinners';

// @mui/material components
import {Switch, } from "@mui/material";
import {withStyles} from "@mui/styles";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Input from "components/input/input";
import ActionsContainer from "components/form/ActionsContainer";
import FormContainer from "views/configuration/components/FormContainer";
import CancelButton from "views/configuration/components/CancelButton";
import SubmitButton from "views/configuration/components/SubmitButton";

// styles
import formStyle from "assets/jss/views/roles/formStyle";
import {primaryColor} from "assets/jss/main";

// helpers
import {capitalize} from "utils/capitalize";
import {Authorizations} from "utils/authorizations";

class Form extends Component {
    renderTitle = data => {
        const {classes, createError} = this.props;

        return (
            <div className={classes.fieldContainer}>
                <label htmlFor={`title_${data.input.name}`} className={classes.fieldLabel}>
                    {data.label}
                </label>
                <Input
                    {...data.input}
                    type={data.type}
                    required={data.required}
                    id={`title_${data.input.name}`}
                    inputRootCustomClasses={classes.input}
                    inputProps={{
                        placeholder: data.placeholder,
                        type: data.type,
                        required: data.required,
                        ...data.input,
                        autoComplete: data.autoComplete
                    }}
                    formControlProps={{
                        className: classes.formControl
                    }}
                />
                {createError && createError.errors && createError.errors[data.input.name] &&
                <span className={classes.errorMessages}>
                    <FormattedMessage id={createError.errors[data.input.name]}/>
                </span>
                }
            </div>
        );
    };

    renderAuthorizations = data => {
        return (
            <Switch
                color="primary"
                onChange={() => this.handleChangeSwitch(data)}
                checked={data.checked}
                value={data.id}
            />
        );
    };

    handleChangeSwitch(data) {
        const {values} = this.props;

        if (values && values.authorizations) {
            let newAuthorizations = values.authorizations;
            let index = newAuthorizations.indexOf(data.id);

            if (index === -1) {
                newAuthorizations.push(data.id);
                data.input.onChange(newAuthorizations);
            } else {
                newAuthorizations.splice(index, 1);
                data.input.onChange(newAuthorizations);
            }
        } else {
            data.input.onChange([data.id]);
        }
    }

    getAuthorizationGroup(name) {
        for (let item in Authorizations) {
            let authorizationName = Authorizations[item];

            if (authorizationName === name) {
                return authorizationName.substring(0, authorizationName.indexOf("."))
            }
        }
    }

    isChecked(id) {
        const {values} = this.props;

        if (values && values.authorizations) {
            return values.authorizations.indexOf(id) !== -1;
        }

        return false
    };

    render() {
        const {classes, intl, values, authorizationsLoading, authorizationsList, createLoading, createError, closeDialog, submitting} = this.props;

        const isFormValid = !! (values && values.name && (values.authorizations && values.authorizations.length > 0));

        let authorizationsOption = [];
        authorizationsOption["global"] = [];
        authorizationsOption["marketplace"] = [];
        authorizationsOption["picking"] = [];
        authorizationsOption["personal_shopper"] = [];
        authorizationsOption["stock_easy"] = [];
        authorizationsOption["statistics"] = [];
        authorizationsOption["other"] = [];
        authorizationsOption["api"] = [];

        authorizationsList && authorizationsList["hydra:member"].forEach(authorization => {
            if (!process.env.REACT_APP_RETURN_ENABLED && (authorization.name === Authorizations.PICKING_RETURN_MANAGEMENT || authorization.name === Authorizations.PICKING_RETURN_ADMIN)) {
                return;
            }
            const authorizationGroup = this.getAuthorizationGroup(authorization.name);

            if (authorizationsOption[authorizationGroup]) {
                authorizationsOption[authorizationGroup].push(authorization);
            }
        });

        return (
            <form onSubmit={this.props.handleSubmit}>
                <FormContainer>
                    <GridItem xs={12} sm={12}>
                        <Field
                            component={this.renderTitle}
                            name={"name"}
                            label={intl.formatMessage({id: "role.form.label.title"})}
                            type={"text"}
                            format={(value) => value ? capitalize(value) : ""}
                            placeholder={"Titre"}
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <h4>{<FormattedMessage id={"role.form.label.authorizations"}/>}</h4>
                        {createError && createError.errors && createError.errors["authorizations"] &&
                        <span className={classes.errorMessages}>
                            <FormattedMessage id={createError.errors["authorizations"]}/>
                        </span>
                        }
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                    {authorizationsLoading &&
                        <div className={classes.propagateLoader}>
                            <PropagateLoader
                                sizeUnit={"px"}
                                size={16}
                                color={primaryColor}
                                loading={authorizationsLoading}
                            />
                        </div>
                    }
                    {!authorizationsLoading && Object.keys(authorizationsOption).map((key) => (
                        <div className={classes.fieldContainer} key={key}>
                            <label className={classes.fieldLabel}>
                                {<FormattedMessage id={`role.form.label.${key}`}/>}:
                            </label>
                            <GridContainer>
                            {authorizationsOption[key].map(authorization => (
                                <GridItem xs={12} sm={6} key={authorization["@id"]}>
                                    <Card className={classes.card}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem className={classes.authorizationName} xs={10} sm={10}>
                                                    <span>{authorization.name}</span>
                                                </GridItem>
                                                <GridItem xs={2} sm={2}>
                                                    <Field
                                                        checked={this.isChecked(authorization["@id"])}
                                                        id={authorization["@id"]}
                                                        component={this.renderAuthorizations}
                                                        name={"authorizations"}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            ))}
                            </GridContainer>
                        </div>
                    ))}
                    </GridItem>
                </FormContainer>
                <ActionsContainer>
                    <CancelButton onClick={closeDialog} />
                    <SubmitButton
                        isEditing={true}
                        disabled={!isFormValid || submitting}
                        loading={createLoading}
                    />
                </ActionsContainer>
            </form>
        )
    }
}

const mapStateToProps = state => {
    return {
        createLoading: state.role.create.loading,
        createError: state.role.create.error,
        values: state.form && state.form.addRole && state.form.addRole.values
    }
};

export default reduxForm({
    form: "addRole",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(
    mapStateToProps,
    null
)(withStyles(formStyle)(injectIntl(Form))));
