import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Grid} from '@mui/material';

import Card from 'components/page/Card';
import CardBody from 'components/card/cardBody';
import GridContainer from 'components/grid/gridContainer';
import GridItem from 'components/grid/gridItem';
import CardTitle from 'components/card/CardTitle';
import CardHeader from 'components/card/cardHeader';
import SaveButton from 'components/button/SaveButton';

import {postRetailerTranslationTag, updatePatch} from 'api/retailer/update';

import FormBloc from "../../../components/form/FormBloc";
import FormTranslator from "../../../components/translator/formTranslator";
import Label from "../../../components/input/new/Label";
import InputTextField from "../../../components/input/new/InputTextField";
import CustomSwitch from "../../../components/switch/CustomSwitch";
import * as yup from "yup";
import {lazy} from "yup";
import mapValues from "lodash/mapValues";
import store from "../../../store";
import {RetailersTagPosition} from "../../tag/components/tagPosition";
import SelectTitle from "../../../components/radioGroup/SelectTitle";
import RadioGroup from "../../../components/radioGroup/RadioGroup";
import FormControlLabel from "../../../components/radioGroup/FormControlLabel";
import getTranslationInitialValues from "../../../utils/getTranslationInitialValues";
import ActionsContainer from "../../../components/containers/ActionsContainer";
import populateMissingTranslations from "../../../utils/populateMissingTranslations";
import {changeSlide} from "react-slick/lib/utils/innerSliderUtils";
import {create} from "../../../api/retailerTranslations/create";

Tags.propTypes = {
    retailer: PropTypes.object.isRequired
};

function Tags({retailer, ...props}) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [tagPosition, setTagPosition] = useState([])
    const [tagColor, setTagColor] = useState([])

    const initialValues = {
        'translations': populateMissingTranslations(retailer?.translations) || getTranslationInitialValues(),
        'tagEnabled': retailer.tagEnabled ?? false,
        'tagPriority': retailer.tagPriority ?? 0,
        'tagColor': retailer.tagColor ?? '',
        'tagPosition': retailer.tagPosition ?? ''
    };

    const validationSchema = yup.object({
        tagEnabled: yup.bool(),
        tagPriority: yup.number()
            .typeError(props.intl.formatMessage({id: 'retailer.form.priority.error.type'}))
            .positive(props.intl.formatMessage({id: 'retailer.form.priority.error.positive'}))
            .min(0)
            .required(props.intl.formatMessage({id: 'retailer.form.priority.error.required'})),
        translations: lazy(obj => yup.object(
            mapValues(obj, () => yup.object({
                tagLabel: yup.string().required(props.intl.formatMessage({id: 'list.form.value.required'}))
            }))
        )),
        tagColor: yup.string()
            .required(props.intl.formatMessage({id: 'retailer.form.color.error.required'})),
        tagPosition: yup.string()
            .required(props.intl.formatMessage({id: 'retailer.form.position.error.required'}))
    });
    function saveRetailerTag(values, actions) {

        let update = false

        // Convert tagPriority from string to int
        values.tagPriority = parseInt(values.tagPriority)

        setLoading(true)
        setSuccess(false)

        const translationObject = values.translations
        const {translations, ...newValues} = values

        let translationPromises = []
        Object.keys(translations).forEach((locale) => {
            const translation = translations[locale]
            if (!translation['@id']) {
                translationPromises.push(create(retailer['@id'], translation))
            } else {
                translationPromises.push(updatePatch(translation, {tagLabel: translation.tagLabel}))
            }
        })

        Promise.all(translationPromises).then(() => {
            updatePatch(retailer, newValues).then(() => {
                setSuccess(true);
            }).finally(() => {
                actions.setSubmitting(false);
                setLoading(false);
            })
        })
    }

    function getTagNameByLabel(value) {
        return tagPosition.find((tag) => tag.value === value)?.label
    }

    function getColorByLabel(value) {
        if (value === '') {
            return ''
        }
        return <FormattedMessage id={"retailer.form.label.tagColor."+value} />
    }

    useEffect(() => {
        // Setting the tag colors
        let colorsInStore = store.getState().currentOrganization?.retrieved?.tagColors ?? []
        if (colorsInStore !== []) {
            let colors = Object.entries(colorsInStore).map(([key, val]) => {
                return {label: key, value: val};
            })
            setTagColor(colors);
        }

        // Setting the tag position
        const formattedOptions = [];
        Object.entries(RetailersTagPosition).forEach(entry => {
            const [name, value] = entry;
            formattedOptions.push({
                'value': value,
                'label': props.intl.formatMessage({id: 'corner.form.label.position.' + name.toLowerCase()})
            });
        });
        setTagPosition(formattedOptions)
    }, []);

    return (
        <Card>
            <CardHeader color="info">
                <GridContainer>
                    <GridItem xs={9} sm={10}>
                        <CardTitle titleId={'retailer.form.title.tabLabel'}/>
                    </GridItem>
                </GridContainer>
            </CardHeader>
            <CardBody
                style={{overflow: "unset"}}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validateOnChange={true}
                    isInitialValid={false}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting}) => {
                        saveRetailerTag(values, {setSubmitting});
                    }}
                >
                    {({
                          isSubmitting,
                          isValid,
                          handleBlur,
                          values,
                          touched,
                          handleChange,
                          errors,
                          setFieldValue
                      }) => (
                        <Form>
                            <Grid container rowSpacing={4}>
                                <Grid item xs={12}>
                                    <CustomSwitch
                                        name="tagEnabled"
                                        checked={values.tagEnabled}
                                        onClick={handleChange}
                                    />
                                    <FormattedMessage id="retailer.form.label.tagEnabled"/>
                                </Grid>
                                <FormBloc titleId={"retailer.form.subtitle.traduction.tagLabel"}>
                                    <Grid container rowSpacing={3}>
                                        <Grid item xs={12}>
                                            <FormTranslator formGenerator={(index, code) => (
                                                <>
                                                    <Label htmlFor={`translations.${code}.tagLabel`}>
                                                        <FormattedMessage id="retailer.form.label.tagLabel"/>
                                                    </Label>
                                                    <InputTextField
                                                        name={`translations.${code}.tagLabel`}
                                                        onChange={handleChange}
                                                        value={values.translations[code]?.tagLabel ?? ''}
                                                        placeholder={props.intl.formatMessage({id: 'retailer.form.placeholder.tagLabel'})}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        error={touched.translations?.[code]?.tagLabel && Boolean(errors.translations?.[code]?.tagLabel)}
                                                        helperText={touched.translations?.[code]?.tagLabel && errors.translations?.[code]?.tagLabel}
                                                    />
                                                </>
                                            )}/>
                                        </Grid>
                                    </Grid>
                                </FormBloc>
                                <FormBloc titleId={"retailer.form.subtitle.parameter.tagLabel"}>
                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={4}>
                                            <Label htmlFor={`retailerTagNamePriority`}>
                                                <FormattedMessage id="retailer.form.label.tagPriority"/>
                                            </Label>
                                            <InputTextField
                                                name={`tagPriority`}
                                                onChange={handleChange}
                                                value={values.tagPriority}
                                                placeholder={props.intl.formatMessage({id: 'retailer.form.placeholder.tagPriority'})}
                                                fullWidth
                                                onBlur={handleBlur}
                                                error={touched.tagPriority && Boolean(errors.tagPriority)}
                                                helperText={touched.tagPriority && errors.tagPriority}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SelectTitle titleTranslationKey='retailer.form.label.tagColor'/>
                                            <RadioGroup
                                                alignListWithButton
                                                name={'tagColor'}
                                                selectedValueLabel={getColorByLabel(values.tagColor)}
                                                value={values.tagColor}
                                                onChange={(event) => {
                                                    setFieldValue('tagColor', event.currentTarget.value)
                                                }}
                                            >
                                                {tagColor.map((color, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={color.value}
                                                        label={<FormattedMessage id={"retailer.form.label.tagColor."+color.value} />}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SelectTitle titleTranslationKey='retailer.form.label.tagPosition'/>
                                            <RadioGroup
                                                alignListWithButton
                                                name={'tagPosition'}
                                                selectedValueLabel={getTagNameByLabel(values.tagPosition)}
                                                value={values.tagPosition}
                                                onChange={(event) => {
                                                    setFieldValue('tagPosition', event.currentTarget.value)
                                                }}
                                            >
                                                {tagPosition.map((position, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={position.value}
                                                        label={position.label}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </FormBloc>
                                <Grid item xs={12}>
                                    <ActionsContainer>
                                        <SaveButton
                                            type={"submit"}
                                            disabled={loading || !isValid || isSubmitting}
                                            helperText={props.intl.formatMessage({id: "retailer.update.tag.success"})}
                                            showHelperText={isSuccess}
                                        >
                                            <FormattedMessage id={"retailer.update.tag.save"}/>
                                        </SaveButton>
                                    </ActionsContainer>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}
export default injectIntl(Tags);
