import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import {Formik, Form as FormikForm} from "formik";
import * as yup from "yup";
import {lazy} from "yup";
import mapValues from "lodash/mapValues";

import Grid from "@mui/material/Grid";

import FormBloc from "components/form/FormBloc";
import FormTranslator from "components/translator/formTranslator";
import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";
import ActionsContainer from "components/containers/ActionsContainer";
import CancelButtonStyled from "components/button/CancelButtonStyled";
import SaveButton from "components/button/SaveButton";

import {updatePatch} from "api/optionValueTranslations/update";
import {create, createBelowPosition} from "api/optionValues/create";
import {update} from "api/optionValues/update";

import {ListContext} from "components/List/context/ListContext";

import getTranslationInitialValues from "utils/getTranslationInitialValues";
import ColorPickerField from "../../../../../components/input/new/ColorPickerField";

Form.propTypes = {
    selectedOptionValue: PropTypes.object,
    refreshList: PropTypes.func,
    createBelow: PropTypes.object,
    lastPosition: PropTypes.number.isRequired,
    option: PropTypes.object.isRequired,
};

function Form({selectedOptionValue, refreshList, createBelow, lastPosition, option, ...props}) {
    if (!option) {
        return null;
    }

    const {loading, closeCreateDialog} = useContext(ListContext);

    const validationSchema = yup.object({
        translations: lazy(obj => yup.object(
            mapValues(obj, () => yup.object({
                name: yup.string().required(props.intl.formatMessage({id: 'list.form.value.required'}))
            }))
        ))
    });

    const initialValues = {
        'translations': selectedOptionValue?.translations || getTranslationInitialValues(),
        'metadata': selectedOptionValue?.metadata || {}
    };

    function updateOption(values) {
        if (!values.translations) {
            return;
        }

        // only update the name
        const allPatchRequest = Object.values(values.translations).map(translation =>
            updatePatch(translation, { name: translation.name })
        );

        // update the metadata
        allPatchRequest.push(update(selectedOptionValue.id, { metadata: values.metadata }));

        // wait the end of all patch update, before refresh the list
        return Promise.all(allPatchRequest);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={(values, {setSubmitting}) => {
                let promise;

                if (selectedOptionValue) { // edit
                    promise = updateOption(values);
                } else { // create
                    if (createBelow?.position) {
                        values.position = createBelow.position;
                        promise = createBelowPosition(option.id, values);
                    } else {
                        values.position = lastPosition + 1;
                        promise = create(option.id, values);
                    }
                }

                promise
                    .then(() => refreshList())
                    .finally(() => setSubmitting(false))
                ;
            }}
        >
            {({
                  isSubmitting,
                  isValid,
                  values,
                  handleBlur,
                  touched,
                  errors,
                  handleChange,
                }) => (
                <FormikForm>
                    <Grid container rowSpacing={6}>
                        <FormBloc
                            titleId={selectedOptionValue ? 'optionValues.form.title.edit' : 'optionValues.form.title'}
                            subtitleId='list.form.translations.required'
                        >
                            <Grid container rowSpacing={3}>
                                <Grid item xs={12}>
                                    <FormTranslator formGenerator={(index, code) => (
                                        <>
                                            <Label htmlFor={`translations.${code}.name`}>
                                                <FormattedMessage id="optionValues.form.name.label" />
                                            </Label>
                                            <InputTextField
                                                name={`translations.${code}.name`}
                                                onChange={handleChange}
                                                value={values.translations[code]?.name ?? ''}
                                                placeholder={props.intl.formatMessage({id: 'optionValues.form.name.placeholder'})}
                                                fullWidth
                                                autoFocus
                                                onBlur={handleBlur}
                                                error={touched.translations?.[code]?.name && Boolean(errors.translations?.[code]?.name)}
                                                helperText={touched.translations?.[code]?.name && errors.translations?.[code]?.name}
                                            />
                                        </>
                                    )} />
                                    { option.type === 'Color' &&
                                        <Grid item xs={12}>
                                            <ColorPickerField
                                                handleChange={handleChange}
                                                value={values.metadata['hexadecimal_color'] ?? null}
                                                intl={props.intl}
                                                name={'metadata.hexadecimal_color'}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </FormBloc>
                        <Grid item xs={12}>
                            <ActionsContainer>
                                <CancelButtonStyled
                                    disabled={isSubmitting}
                                    onClick={closeCreateDialog}
                                >
                                    <FormattedMessage id="list.button.cancel" />
                                </CancelButtonStyled>
                                <SaveButton
                                    type={"submit"}
                                    disabled={loading || !isValid || isSubmitting}
                                >
                                    {selectedOptionValue ? (
                                        <FormattedMessage id="list.button.edit" />
                                    ) : (
                                        <FormattedMessage id="list.button.add" />
                                    )}
                                </SaveButton>
                            </ActionsContainer>
                        </Grid>
                    </Grid>
                </FormikForm>
            )}
        </Formik>
    );
}

export default injectIntl(Form);
