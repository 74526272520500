export const Authorizations = {
    GLOBAL_ORGANIZATION_MANAGEMENT : 'global.organization.management', // "Top-level" authorization, cannot be assigned to a Member affected to a Retailer.
    GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT : 'global.organization.eshop.management', // "Top-level" authorization, cannot be assigned to a Member affected to a Retailer.
    GLOBAL_ORGANIZATION_ACCOUNTING_MANAGEMENT : 'global.organization.accounting.management',
    GLOBAL_ORGANIZATION_SUPERVISOR : 'global.organization.supervisor',
    GLOBAL_ROLE_MANAGEMENT : 'global.role.management',
    GLOBAL_MEMBER_MANAGEMENT : 'global.member.management',
    GLOBAL_PRINTNODE_NOTIFICATION : 'global.printnode.notification',

    MARKETPLACE_RETAILER_VIEW : 'marketplace.retailer.view', // "Top-level" authorization, cannot be assigned to a Member affected to a Retailer.
    MARKETPLACE_RETAILER_MANAGEMENT : 'marketplace.retailer.management', // "Top-level" authorization, cannot be assigned to a Member affected to a Retailer.
    MARKETPLACE_FEED_MANAGEMENT : 'marketplace.feed.management',
    MARKETPLACE_OPTION_MANAGEMENT : 'marketplace.option.management',
    MARKETPLACE_ATTRIBUTES_MANAGEMENT : 'marketplace.attribute.management',
    MARKETPLACE_PRODUCT_VIEW : 'marketplace.product.view',
    MARKETPLACE_PRODUCT_MANAGEMENT : 'marketplace.product.management',
    MARKETPLACE_PHYSICALENTRY_MANAGEMENT : 'marketplace.physical_entry.management',
    MARKETPLACE_CATEGORY_MANAGEMENT: 'marketplace.category.management',
    MARKETPLACE_MERCHANDISING: 'marketplace.merchandising',
    MARKETPLACE_CANCELLATION_REASON_MANAGEMENT: 'marketplace.cancellation_reason.management',
    MARKETPLACE_OPTIN_EXPORT: 'marketplace.optin.export',
    MARKETPLACE_GUEST_EXPORT: 'marketplace.guest.export',
    MARKETPLACE_COMPLIANCE: 'marketplace.compliance',

    PICKING_MANAGER : 'picking.manager', // "Top-level" authorization, cannot be assigned to a Member affected to a Retailer.
    PICKING_RUNNER : 'picking.runner', // "Top-level" authorization, cannot be assigned to a Member affected to a Retailer.
    PICKING_NOTIFICATION : 'picking.notification',
    PICKING_ACCOUNTING : 'picking.accounting',
    PICKING_RETAILER : 'picking.retailer',
    PICKING_RETAILER_SHIPMENT : 'picking.retailer.shipment',
    PICKING_RETAILER_CONFIRM : 'picking.retailer.confirm',
    PICKING_RETAILER_SEND : 'picking.retailer.send',
    PICKING_RETAILER_CANCEL : 'picking.retailer.cancel',
    PICKING_RETAILER_REFUND : 'picking.retailer.refund',
    PICKING_RETAILER_C_C : 'picking.retailer.cc',
    PICKING_RETAILER_DRIVE : 'picking.retailer.drive',
    PICKING_READ_ONLY : 'picking.read.only',
    PICKING_RETAILER_QUOTE : 'picking.retailer.quote',
    PICKING_STATISTICS_QUOTES: 'picking.statistics.quotes',
    PICKING_RETURN_ADMIN: 'picking.return.admin',
    PICKING_RETURN_MANAGEMENT: 'picking.return.management',
    PICKING_PRINTERS: 'picking.printers',

    PERSONALSHOPPER_MANAGEMENT : 'personal_shopper.management', // "Top-level" authorization, cannot be assigned to a Member affected to a Retailer.

    STOCK_EASY_ACCESS: 'stock_easy.access', // "Top-level" authorization, can be assigned to a Member affected to a Retailer.
    STOCK_EASY_CATALOG_PRODUCT_SETTINGS: 'stock_easy.catalog.product.settings',

    STATISTICS_ACCESS: 'statistics.access',
    STATISTICS_OPE: 'statistics.ope',

    OTHER_HELPDESK_CLIENT: 'other.helpdesk.client', // change the helpdesk link in sidebar
    OTHER_HELPDESK_MERCHAND: 'other.helpdesk.merchand', // default helpdesk link in sidebar
    OTHER_RETAILER_PROFILE: 'other.retailer.profile',

    API_RETAILER_FEED: 'api.retailer.feed',
}

export function hasAuthorization(authorizations, authorization) {
    return authorizations.includes(authorization);
}
