export const FeedType = {
    STOCK: 'stock',
    MERCH: 'merch',
}

export function getAllFeedType() {
    return [
        FeedType.STOCK,
        FeedType.MERCH,
    ]
}
