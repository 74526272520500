import React from 'react';
import PropTypes from 'prop-types';

import eikowTag from 'assets/img/eikow.svg';
import feedTag from 'assets/img/feed.svg';

ProductOriginTag.propTypes = {
    isEikowProduct: PropTypes.bool,
    hasVariationFromFeed: PropTypes.bool
};

function ProductOriginTag({isEikowProduct = false, hasVariationFromFeed = false}) {
    if (isEikowProduct) {
        return <img src={eikowTag} alt={'eikow'} />
    } else if (hasVariationFromFeed) {
        return <img src={feedTag} alt={'feed'} />
    } else {
        return null;
    }
}

export default ProductOriginTag;
