import React, {useEffect, useState, useContext} from "react";
import Grid from '@mui/material/Grid';
import {fetch} from "utils/dataAccess";
import MerchandisingTableContainer from "domain/components/merchandising/MerchandisingTableContainer";
import ApplicationContainer from "components/applicationContainer";
import Navigation from "views/marketplace/merchandising/navigation";
import {FilterContext} from "contexts/filterContext";
import {MerchandisingContext} from "contexts/merchandisingContext";
import Filters from "views/marketplace/merchandising/filter/filters"
import Search from "views/marketplace/merchandising/search";
import RunningActions from "views/marketplace/merchandising/runningActions";
import {useSnackbar} from "notistack";
import {FormattedMessage} from "react-intl";
import {StoreContext} from "contexts/storeContext";
import {PusherContext} from "contexts/pusherContext";
import {ProductDataContext} from "contexts/productDataContext";

function MerchandisingContainer() {
    const [products, setProducts] = useState();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [totalItems, setTotalItems] = useState(0);
    const [ filtersLoaded, setFiltersLoaded ] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState([]);
    const [searchInput, setSearchInput] = useState(null);
    const [notificationReceived, setNotificationReceived] = useState(false);
    const navParam = useContext(MerchandisingContext);
    const { categoriesLoaded, categoriesFilters,
        retailersLoaded, retailersFilters,
        cornersLoaded, cornersFilters,
        statusLoaded, statusFilters,
        gendersLoaded, gendersFilters,
        optionsLoaded, optionsFilters,
        brandsLoaded, brandsFilters,
        channelsLoaded, channelsFilters
    } = useContext(ProductDataContext);
    const { filterParams} = useContext(FilterContext);
    const { enqueueSnackbar } = useSnackbar();
    const store = useContext(StoreContext);
    const organization = store.getState()?.currentOrganization?.retrieved;
    const { setOrganization } = useContext(PusherContext);

    const buildBody = (baseRequest = false) =>  {
        let body = {
            itemsPerPage: itemsPerPage,
            page: page + 1,
        };

        if (navParam.sortField !== null) {
            body.sort = {[navParam.sortField] : {'order': navParam.sortDirection}};
        }

        if (baseRequest) {
            return body;
        }

        if (navParam.navigationType) {
            body.navigationType = navParam.navigationType;
            body.navigationValue = navParam.navigationValue;
        }

        if (searchInput) {
            body.searchInput = searchInput;
        }

        return {...body, ...filterParams}
    }

    const handleExport = (selectAll, selectedProducts) => {
        let headers = new Headers();
        headers.set('Accept', 'application/json');

        let body = {};
        if (selectAll) {
            body = buildBody();
        } else {
            body.ids = selectedProducts;
        }

        // selectedProducts does not contain all products when selectAll is true
        if (totalItems > 10000) {
            enqueueSnackbar(<FormattedMessage id={"merchandising.show.card.export_limit_error"}/>, {variant: 'error'});
            return;
        }

        fetch("/merchandising/export", {method: "POST", body: JSON.stringify(body), headers}).then(response => {
            enqueueSnackbar(<FormattedMessage id={"merchandising.show.card.exported"}/>, {variant: 'success'});
        });
    }

    const handlePagination = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearchInput = (value) => {
        setSearchInput(value);
    }

    useEffect(() => {
        if (categoriesLoaded &&
            retailersLoaded &&
            cornersLoaded &&
            statusLoaded &&
            gendersLoaded &&
            brandsLoaded &&
            channelsLoaded &&
            optionsLoaded
        ) {
            setFiltersLoaded(true)

            let finalFilters = [
                categoriesFilters,
                retailersFilters,
                cornersFilters,
                statusFilters,
                gendersFilters,
                brandsFilters,
                optionsFilters
            ];

            if (channelsFilters.tabContent.items.length > 1) {
                // add channels filters before options filters
                let indexBeforeLast = finalFilters.length - 1;
                finalFilters.splice(indexBeforeLast, 0, channelsFilters);
            }

            setFilters(finalFilters);
        }
    }, [
            categoriesLoaded,
            retailersLoaded,
            cornersLoaded,
            statusLoaded,
            gendersLoaded,
            brandsLoaded,
            channelsLoaded,
            optionsLoaded
        ]
    );

    useEffect(() => {
        /* It's a bit obscure. Resetting the page will call the other useEffect leading to the reload of the list */
        setPage(0);
        /* If the page is already 0, there's no component update. Meaning there's no reload of the list. In this
        * only case we need to manually reload it */
        if (page === 0) {
            reloadList();
        }
    },[searchInput, filterParams]);

    useEffect(() => {
        if  (navParam.shouldReload) {
            setPage(0);
            if (page === 0) {
                reloadList();
            }
        }
    }, [navParam])

    useEffect(() => {
        reloadList();
    },[itemsPerPage, page]);

    const reloadList = () => {
        setProducts([]);
        setLoading(true);

        let headers = new Headers();
        headers.set('Accept', 'application/json');

        // Fetch without filters if shouldReset is defined and true
        let body = buildBody(navParam.shouldReset ?? false);
        fetch("/merchandising", {method: "POST", body: JSON.stringify(body), headers}).then(
            response => {
                response.json().then(retrieved => {
                    setProducts(retrieved.data);
                    setTotalItems(retrieved.totalItems);
                    setLoading(false);
                });
            }
        );
    }

    /* Little trick. The .bind() of pusherClient fix the value of variables on instanciation. Meaning any later call will
    * have default value at the moment of instanciation. So the reloadList should be called out of the .bind() context */
    useEffect(() => {
        reloadList();
        setNotificationReceived(false);
    }, [notificationReceived]);

    useEffect(() => {
        // Setting organization for pusherContext
        setOrganization(organization);
    }, [organization]);

    return (
        <ApplicationContainer header={<Search handleSearch={handleSearchInput}/>}>
            <Filters filters={filters} loaded={filtersLoaded} />
            <Grid container spacing={1}>
                <Grid item xs={3} sx={{ marginTop: '15px' }}>
                    <Navigation loadingHandler={setLoading} loaded={filtersLoaded}/>
                </Grid>
                <Grid item xs={9}>
                    <RunningActions reloadHandler={() => setNotificationReceived(true)}/>
                    <MerchandisingTableContainer
                        products={products}
                        page={page}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        handlePagination={handlePagination}
                        handleChangeItemsPerPage={handleChangeItemsPerPage}
                        loading={loading}
                        handleExport={handleExport}
                        buildBody={buildBody}
                    />
                </Grid>
            </Grid>
        </ApplicationContainer>
    );
}

export default (MerchandisingContainer);
