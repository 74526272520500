import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
// "react-dropzone" is broken
// https://github.com/react-dropzone/react-dropzone/issues/1259#issuecomment-1742141848
import {useDropzone} from "react-dropzone-esm";

import Grid from "@mui/material/Grid";

// local components
import ImportZone from "components/input/ImageDropField/components/ImportZone";
import Preview from "./components/Preview";
import Errors from "./components/Errors";
import TotalImages from "./components/TotalImages";

import {ImageDropFieldContext} from "./context/ImageDropFieldContext";

MultiImageDropField.propTypes = {
    accept: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    fileSupportTypes: PropTypes.array,
    onDrop: PropTypes.func,
    onDelete: PropTypes.func,
    recommendedFormat: PropTypes.string,
    disabled: PropTypes.bool
};

MultiImageDropField.defaultProps = {
    accept: {
        'image/jpeg': [],
        'image/png': []
    },
    fileSupportTypes: ['JPG', 'PNG']
}

function MultiImageDropField({accept, fileSupportTypes, recommendedFormat, disabled, onPicturesDrop, onPictureDelete, onPicturesReordered, pictures, ...props}) {
    const [loading, setLoading] = useState(false)
    const [isValid, setValid] = useState(true);

    const [files, setFiles] = useState([]);

    // on pictures change
    useEffect(() => {
        if (!pictures) return;

        const newFiles = pictures.map((pic) => ({
            ...pic,
            preview: pic.contentUrl
        }))

        setFiles(newFiles);
    }, [pictures])

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    function deleteFile(pictureToDeleteIndex) {
        let newFiles = [...files];

        newFiles.splice(pictureToDeleteIndex, 1);

        setFiles(newFiles);
    }

    function removeLoader() {
        setLoading(false);
    }

    const {getRootProps, getInputProps, open, fileRejections} = useDropzone({
        accept: accept,
        // max files we can select
        // maybe set the max defined minus the number of photos already present ? and if max reached, disable the field
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            setValid(true);

            if (acceptedFiles.length === 0) {
                return;
            }

            setLoading(true)

            if (onPicturesDrop) {
                onPicturesDrop(acceptedFiles, removeLoader);
            } else {
                const newAcceptedFiles = acceptedFiles?.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }))

                setFiles([...files, ...newAcceptedFiles]);

                setLoading(false);
            }
        },
        onDropRejected: (fileRejections) => {
            setValid(false);
        }
    });

    function handleSortPictures(result) {
        const {draggableId, source, destination} = result;

        const fromPosition = source.index;
        const toPosition = destination.index;

        let pictures = files;
        let element = pictures[fromPosition];

        pictures.splice(fromPosition, 1);
        pictures.splice(toPosition, 0, element);

        setFiles(pictures);

        if (onPicturesReordered) {
            onPicturesReordered(pictures)
        }
    }

    return (
        <ImageDropFieldContext.Provider value={{
            openFileSelector: open,
            disabled: disabled || loading,
            importLoading: loading,
            inputProps: getInputProps(),
            rootProps: getRootProps(),
            fileRejections: fileRejections
        }}>
            <Grid
                container
                flexWrap='nowrap'
                position='relative'
            >
                <Grid item marginRight={2}>
                    <ImportZone isMulti={true} />
                </Grid>
                <Grid
                    item
                    container
                    overflow={'scroll'}
                    paddingBottom={'14px'}
                >
                    <DragDropContext onDragEnd={(res) => handleSortPictures(res)}>
                        <Droppable droppableId={"list"} direction={"horizontal"}>
                            {provided => (
                                <div
                                    style={{ display: "flex", gap: '16px'}}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {files.map((file, index) => {
                                        return (
                                            <Draggable
                                                isDragDisabled={disabled}
                                                key={file.preview}
                                                index={index}
                                                draggableId={file.preview}
                                            >
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Preview
                                                            isMulti
                                                            imgSrc={file.preview}
                                                            onDelete={() => onPictureDelete ? onPictureDelete(index) : deleteFile(index)}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
               <TotalImages count={files.length} />
            </Grid>
            <Errors />
        </ImageDropFieldContext.Provider>
    );
}

export default MultiImageDropField;
