import React, {Component} from "react";
import {FormattedDate, FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {BeatLoader} from "react-spinners";

import {
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Table,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
} from "@mui/material";

import {
    ArrowBackIos,
    Check,
    Close,
} from "@mui/icons-material";

// Styles
import listErrorsStyle from "../../../../../assets/jss/views/report/errorsListStyle";

// Actions
import {get} from "../../../../../actions/report/get";
import {list} from "../../../../../actions/report/errors/list";
import PaginationChangeHandler from "../../../../../components/pagination/paginationChangeHandler";
import {withStyles} from "@mui/styles";

class HistoryShow extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount () {
        this.props.get(this.props.match.params.hid);
        this.props.getErrors(this.props.match.params.hid, 1);
    }

    static getDerivedStateFromProps(nextProps, nextContext) {
        const {errors, report} = nextProps;
        return {errors, report};
    }

    redirectToList = () => {
        const url = this.props.match.url;
        const lastSlashPosition = url.lastIndexOf('/');
        const redirectUrl = url.substr(0, lastSlashPosition);

        this.props.history.push({
            pathname:redirectUrl,
            type:"parent",
        })
    }

    renderBackToListButton = () => {
        const { classes } = this.props;
        return (
            <div className={classes.actionsContainer}>
                <Button className={classes.buttonReturn} onClick={this.redirectToList}>
                    <ArrowBackIos/>
                    <FormattedMessage id={"feed.form.back"}/>
                </Button>
            </div>
        );
    }

    renderErrorsList(errors) {
        const { classes } = this.props;
        if (!errors || errors.length === 0) {
            return (<div className={classes.container}>
                <FormattedMessage id={'feed.history.detail.label.no_error'}/>
                {this.renderBackToListButton()}
            </div>);
        }

        return (
            <Card className={classes.container}>
                <CardHeader
                    action={
                        <div>
                            {this.renderBackToListButton()}
                            <PaginationChangeHandler
                                pageChanged={(page) => this.props.getErrors(this.props.match.params.hid, page)}
                                retrieved={this.props.errors}
                            />
                        </div>
                        }
                    title={<h4><FormattedMessage id={'feed.history.detail.label.errors'}/></h4>}
                />


                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><FormattedMessage id={'feed.history.expansion.error.detail.line'}/></TableCell>
                                <TableCell><FormattedMessage id={'feed.history.detail.label.error.type'}/></TableCell>
                                <TableCell><FormattedMessage id={'feed.history.detail.label.error.severity'}/></TableCell>
                                <TableCell><FormattedMessage id={'feed.history.detail.label.error.sku'}/></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {errors.map(error => (
                                <TableRow key={error['@id']}>
                                    <TableCell>{error.line}</TableCell>
                                    <TableCell>{error.type}</TableCell>
                                    <TableCell>{error.severity}</TableCell>
                                    <TableCell>{error.sku}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        );
    }

    render() {
        const { classes, report, errors } = this.props;

        if (!report) {
            return (
                <BeatLoader
                    sizeUnit={"px"}
                    size={5}
                    color={"#5e5b5b"}
                />
            );
        }

        return (
            <div className={classes.containerWithSidebar}>
                <Card className={classes.container}>
                    <Grid container>
                        <Grid item xs={6}>
                            <p className={classes.field}>
                                <FormattedMessage id={'feed.history.detail.label.feedHasChanged'}/> :
                                {!!report.feedHasChanged ? <Check className={classes.yesNo}/> : <Close className={classes.yesNo}/>}
                            </p>
                        </Grid>

                        <Grid item xs={6}>
                            <p className={classes.field}>
                                <FormattedMessage id={'feed.history.detail.label.start'}/> :
                                <FormattedDate
                                    value={new Date(report["startedAt"])}
                                    year={"numeric"}
                                    month={"numeric"}
                                    day={"numeric"}
                                    hour={"numeric"}
                                    minute={"numeric"}
                                    second={"numeric"}
                                />
                            </p>
                        </Grid>

                        <Grid item xs={6}>
                            <p className={classes.field}>
                                <FormattedMessage id={'feed.history.detail.label.forceImport'}/> :
                                {!!report.importForced ? <Check className={classes.yesNo}/> : <Close className={classes.yesNo}/>}
                            </p>
                        </Grid>

                        <Grid item xs={6}>
                            <p className={classes.field}>
                                <FormattedMessage id={'feed.history.detail.label.end'}/> :

                                {!!report["endedAt"] ?
                                    <FormattedDate
                                        value={new Date(report["endedAt"])}
                                        year={"numeric"}
                                        month={"numeric"}
                                        day={"numeric"}
                                        hour={"numeric"}
                                        minute={"numeric"}
                                        second={"numeric"}
                                    /> :
                                    <FormattedMessage id={'feed.list.table.importInProgress'}/>
                                }
                            </p>
                        </Grid>

                        <Grid item xs={6}>
                            <p className={classes.field}>
                                <FormattedMessage id={'feed.history.detail.label.configuredToDisableVariationsNotSeenInFeed'}/> :

                                {!!report.configuredToDisableVariationsNotSeenInFeed ? <Check className={classes.yesNo}/> : <Close className={classes.yesNo}/>}
                            </p>
                        </Grid>

                        <Grid item xs={6}>
                            <p className={classes.field}>
                                <FormattedMessage id={'feed.history.detail.label.starter'}/> :
                                {!!report.starter ?
                                    `  ${report.starter.givenName} ${report.starter.familyName}` :
                                    <FormattedMessage id={'feed.history.detail.label.starter_auto'}/>
                                }
                            </p>
                        </Grid>
                    </Grid>
                </Card>

                {!!errors && this.renderErrorsList(errors['hydra:member'])}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        errors: state.report.listErrors.retrieved,
        report: state.report.get.retrieved,
    };
};


const mapDispatchToProps = dispatch => ({
    get: id => dispatch(get(id)),
    getErrors: (reportId, page) => dispatch(list(reportId, page)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listErrorsStyle)(injectIntl(HistoryShow)));
