import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import StyledReactSelect from "components/select/StyledReactSelect";

import {ProductShowContext} from "../../../context/ProductShowContext";

function ChannelsField(props) {
    const {values, setFieldValue} = useFormikContext();
    const {isStockEasyUser} = useContext(ProductShowContext);

    const [channelsList, setChannelsList] = useState([]);

    const [formattedChannels, setFormattedChannels] = useState([]);

    if (isStockEasyUser) {
        return null;
    }

    useEffect(() => {
       setChannelsList(props.currentOrganization.eshopConfig.channels)
    }, []);

    useEffect(() => {
        const formattedChannels = formatChannelsForReactSelect(channelsList);

        setFormattedChannels(formattedChannels);
    }, [channelsList]);

    function formatChannelsForReactSelect(channelsList) {
        return channelsList.map((key) => ({"value": key['@id'], "label": key.code}));
    }

    function onChange(selectedOptions) {
        let newSelected = [];

        if (!selectedOptions) {
            setFieldValue('channels', newSelected);
            return;
        }

        newSelected = selectedOptions.map((selected) => findChannelInRetrievedChannels(selected.value));

        setFieldValue('channels', newSelected)
    }

    function findChannelInRetrievedChannels(id) {
        return channelsList.find((channel) => channel['@id'] === id)
    }

    function getSelectedValuesName(values) {
        if (!values) return [];

        return values.map((value) => findChannelInFormattedChannels(value['@id']))
    }

    function findChannelInFormattedChannels(id) {
        return formattedChannels.find((channel) => channel.value === id)
    }

    if (!channelsList || channelsList.length <= 1) return null;

    return (
        <>
            <SelectTitle titleTranslationKey='product.show.preferences.channels' />
            <StyledReactSelect
                isMulti
                closeMenuOnSelect={false}
                placeholder={<FormattedMessage id='product.show.product.form.field.corner.placeholder' />}
                name={'corners'}
                value={getSelectedValuesName(values.channels)}
                options={formattedChannels}
                onChange={onChange}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        currentOrganization: state.currentOrganization.retrieved ?? null
    };
};

export default connect(mapStateToProps)(ChannelsField);

