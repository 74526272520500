import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl, FormattedHTMLMessage} from "react-intl";
import {BeatLoader, PropagateLoader} from 'react-spinners';
import {stockEasyOffer, marketPlaceOffer} from "../../enum/offers";

// @mui/material components
import {
    Button as MaterialUiButton,
    ButtonGroup,
    Checkbox,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputBase,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material"

// @mui/icons-material
import {
    ArrowBackIos,
    KeyboardArrowDown,
    KeyboardArrowRight,
    NotificationsActive,
    Search,
    UnfoldMore,
    ViewList,
    Delete, GetApp,
} from "@mui/icons-material";

// core components
import TableHead from "./ProductsTableHead";
import PaginationContainer from "../../../components/pagination/paginationContainer";
import Button from "components/button/button"
import Snackbar from "components/snackbar/snackbar";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import CardFooter from "components/card/cardFooter";
import ProductRow from "./ProductRow";
import FilterMenu from "../../../components/filter/filterMenu";
import ItemsPerPageSelect from "components/select/itemsPerPageSelect";

// styles
import listStyle from "assets/jss/views/product/listStyle";
import {primaryColor} from "assets/jss/main";

// action
import {list, reset, downloadExport} from "actions/product/list";
import {massUpdate, reset as massUpdateReset} from "actions/product/massUpdate";
import {brandList} from "actions/brand/list";
import {categoryList} from "actions/category/list";
import {genderList} from "actions/gender/list";
import retailerOptionValuesList from "api/retailerOptionValues/list"
import {handleSelectProduct, setSelected, setIsSelectAll} from "../../../actions/product/selection";

// utils
import {sortOn} from "utils/sortOn";
import {Authorizations, hasAuthorization} from "utils/authorizations";
import abort from "utils/abort";
import {fetch} from "utils/dataAccess";
import {encodePercentageCharacter} from "utils/encodePercentageCharacter";
import {UserAgentUtils} from "../../../utils/useragent";
import {getTranslation} from "../../helpers/translations";
import {cornerList} from "../../../api/corner/list";
import {withStyles} from "@mui/styles";
import {paramsToObject} from "../../../utils/paramsToObject";
import {Buffer} from 'buffer';
import TotalSelectedMessage from "../../../views/marketplace/retailer/product/components/TotalSelectedMessage";
import TotalSelectedActionMessage from "../../../views/marketplace/retailer/product/components/TotalSelectedActionMessage";

class ProductsTable extends Component {
    static propTypes = {
        error: PropTypes.string,
        retrieved: PropTypes.any, // can be false or an object
        loading: PropTypes.bool,
        list: PropTypes.func,
        brandList: PropTypes.func,
        categoryList: PropTypes.func,
        genderList: PropTypes.func,
        retailerOptionValuesList: PropTypes.func,
        massUpdate: PropTypes.func,
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            itemsPerPage: '50',
            page: 1,
            action: "",
            addCategoriesDialog: false,
            deleteCategoriesDialog: false,
            deleteProductsDialog: false,
            setProductOnCornerDialog: false,
            genderDialog: false,
            brandDialog: false,
            categoriesToAdd: [],
            categoriesToDelete: [],
            genderSelected: '',
            brandSelected: '',
            cornerSelected: '',
            showNotification: true,
            searchCategories: '',
            searchBrand: '',
            showChildren: [],
            isTotalSelected: false,
            totalProductSelected: null,
            loadingAllProduct : false,
            allGendersFetched : false,
            allBrandsFetched : false,
            allCategoriesFetched : false,
            allGenders : [],
            allBrands : [],
            allCategories : [],
            baseParamsUrl: this.props.offer === stockEasyOffer ? '/stockeasy/show/retailers/' :'/retailers/show/',
            isChecked: false,
            timer: null,
            retailerOptionValuesList: null
        };

        this.handleChangeAction = this.handleChangeAction.bind(this);
    };

    componentDidMount() {
        const {list} = this.props;

        let cornerFilter = "enabled=true";
        if (this.props.offer === stockEasyOffer) {
            this.setState({ baseParamsUrl: '/stockeasy/show/retailers/' });
            cornerFilter = "enabled=true&public=true";
        }

        cornerList(cornerFilter).then(corners => {
            this.setState({
                allCorners: corners,
                cornerSelected: corners.length > 0 ? corners[0]['@id'] : null,
            });
        });

        let retailerOptionValuesOrdered = [];
        let retailer = decodeURIComponent(this.props.match.params.id);
        retailerOptionValuesList(`pagination=false&optionUsedOnVariation=true&retailer_id=${retailer.replace("/retailers/", "")}`)
            .then(retailerOptions => {
                Object.keys(retailerOptions).forEach(function(optionValue) {
                    const optionName = getTranslation(retailerOptions[optionValue]['option'] ?? {translations: []})?.name;
                    if (!retailerOptionValuesOrdered[optionName]) {
                        retailerOptionValuesOrdered[optionName] = [];
                    }
                    retailerOptionValuesOrdered[optionName].push(retailerOptions[optionValue]);
                });
            })
            .finally(() => this.setState({retailerOptionValuesList: retailerOptionValuesOrdered}));

        if (this.props.match.params.params) {
            let utf8params = Buffer.from(this.props.match.params.params, 'utf-8').toString();
            let params = decodeURIComponent(utf8params);

            this.updateStoreParameters(params);
            list(params);

            if (this.props.offer === marketPlaceOffer) {
                const date = new Date();
                date.setDate(date.getDate() - 7);
            }
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.massUpdateSuccess !== nextProps.massUpdateSuccess) {
            this.props.list(decodeURIComponent(this.props.match.params.params));

            this.setState({
                loadingAllProduct: false,
            });

            this.props.massUpdateReset();
        }

        if (this.props.match.params.params !== nextProps.match.params.params) {
            window.scrollTo(0, 0);

            let utf8params = Buffer.from(nextProps.match.params.params, 'utf-8').toString();
            let params = decodeURIComponent(utf8params);

            nextProps.list(new URLSearchParams(params).toString());

            this.resetProductsSelection();

            this.updateStoreParameters(params);
        }

        if (this.props.categoryRetrieved !== nextProps.categoryRetrieved) {
            this.setState({
                'allCategories': nextProps.categoryRetrieved,
                'allCategoriesFetched': true,
            });
        }
    };

    componentWillUnmount() {
        this.props.reset();
        abort.abortRequests();
    };

    resetSelectedAndCloseDialogAndRefreshList = (dialogName = false) => {
        this.resetProductsSelection();

        if (dialogName) {
            this.handleDialog(dialogName);
        }

        this.props.list(decodeURIComponent(this.props.match.params.params));
    };

    getCategoriesByProduct = (productArray) => {
        let categoriesArray = [];

        productArray.forEach(product => {
            product.categories.forEach(cat => {
                categoriesArray.push(cat);
            })
        });

        return this.groupCategories(categoriesArray);
    };

    groupCategories = (categoriesArray) => {
        let uniqueCatArray = [];

        // check if the parent of variation is already in the array
        categoriesArray.forEach(cat => {
            let isCatAlreadyAdded = uniqueCatArray.some(item => item.id === cat.id)
            if (!isCatAlreadyAdded) {
                uniqueCatArray.push(cat);
            }
        });

        return uniqueCatArray.sort(sortOn("name"));
    };

    handleRequestSort = (event, property) => {
        const params = this.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";
        let order = this.state[`order[${property}]`];

        params.map((param) => {
            if (param.search("order") === -1) {
                newParams += `${param}&`;
            } else {
                delete this.state[`${param.split("=")[0]}`]
            }

            return true;
        });

        if (order !== undefined) {
            order = ("asc" === order) ? "desc" : "asc";
            newParams += `order[${property}]=${order}`;

            this.setState({[`order[${property}]`]: order});
        } else {
            newParams += `order[${property}]=desc`;

            this.setState({[`order[${property}]`]: "desc"});
        }

        this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/${encodeURIComponent(newParams)}`);
    };

    handleSearch = (event) => {
        abort.abortRequests();

        const value = event.target.value;

        this.setState({'translations.name': value});

        clearTimeout(this.state.timer);

        const newTimer = setTimeout(() => {
            let params = this.handlePageReset(decodeURIComponent(encodePercentageCharacter(this.props.match.params.params)));
            params = params.join('&');
            params = new URLSearchParams(params);

            if (value.length > 0) {
                params.set('translations.name', encodePercentageCharacter(value));
            } else {
                params.delete('translations.name');
            }

            this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/${encodeURIComponent(params.toString())}`);

        }, 500);

        this.setState({timer: newTimer});
    };

    handleChangeAction = event => {
        switch (event.target.value) {
            case "active":
                this.handleEditStatusToSelected(true);
                break;
            case "inactive":
                this.handleEditStatusToSelected(false);
                break;
            case "addCategories":
                this.handleDialog("addCategoriesDialog");
                break;
            case "deleteCategories":
                this.handleDialog('deleteCategoriesDialog');
                break;
            case "editGender":
                this.handleDialog("genderDialog");
                break;
            case "editBrand":
                this.handleDialog("brandDialog");
                break;
            case "setCornerOnProducts":
                this.handleDialog("setProductOnCornerDialog");
                break;
            case "deleteProducts":
                this.handleDialog('deleteProductsDialog');
                break;
            default:
        }
    };

    handleSelectAllClick = event => {
        const {retrieved} = this.props;

        if (event.target.checked) {
            let filteredProducts = retrieved["hydra:member"];

            if (this.props.offer === stockEasyOffer) {
                filteredProducts = this.removeEikowAndFeedProductsFromList(retrieved["hydra:member"])
            }

            let newDataArray = JSON.parse(JSON.stringify(filteredProducts));
            // set global redux state product selection selected
            this.props.setSelected(newDataArray);
            this.props.setIsSelectAll(true);

            this.setState({
                totalProductSelected: filteredProducts.length
            });
        } else {
            // reset global redux state product selection selected
            this.resetProductsSelection();
        }
    };

    handleCheckCategoryAdd = value => {
        const {categoriesToAdd} = this.state;
        const currentIndex = categoriesToAdd.indexOf(value);
        const newChecked = [...categoriesToAdd];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            categoriesToAdd: newChecked,
        });
    };

    handleCheckCategoryDelete = value => {
        const {categoriesToDelete} = this.state;
        const currentIndex = categoriesToDelete.indexOf(value);
        const newChecked = [...categoriesToDelete];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            categoriesToDelete: newChecked,
        });
    };

    getParamsForHandlingActionToSelected = (keyType, keyState) => {
        return {
            'retailer' : (this.props.offer === stockEasyOffer) ? decodeURIComponent('/retailers/' + this.props.match.params.id):decodeURIComponent(this.props.match.params.id),
            'url' : decodeURIComponent(this.props.match.params.params),
            'productList' : [],
            'key': [keyType, keyState]
        };
    }

    fetchHandlingActionSelected = (params) => {
        this.setState({ loadingAllProduct: true });

        fetch("/products?" + decodeURIComponent(this.props.match.params.params) + "&itemsPerPage=999999&page=1").then(
            response => {
                response.json().then(retrieved => {
                    let productList = retrieved['hydra:member'];

                    if (this.props.offer === stockEasyOffer) {
                        productList = this.removeEikowAndFeedProductsFromList(productList);
                    }

                    params['productList'] = productList.map((item) => item['@id']);

                    this.props.massUpdate(params);
                });
            }
        );
    }

    handleActionToSelected = (keyType, keyState = null, refreshDialogType = null) => {
        const params = this.getParamsForHandlingActionToSelected(keyType, keyState);

        if (!this.state.isTotalSelected) {
            params['productList'] =  this.props.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else {
            this.fetchHandlingActionSelected(params);
        }

        this.props.setIsSelectAll(false);
        this.resetSelectedAndCloseDialogAndRefreshList(refreshDialogType);
    }

    handleEditStatusToSelected = (status) => {
        this.handleActionToSelected('status', status)
        this.setState({
            inputSelectClicked: false
        });

        this.resetSelectedAndCloseDialogAndRefreshList();
    };

    handleEditBrandToSelected = (brandId) => {
        const params = this.getParamsForHandlingActionToSelected('brand', brandId);

        if (!this.state.isTotalSelected) {
            params['productList'] =  this.props.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else if ("" !== brandId.trim()) {
            this.fetchHandlingActionSelected(params);
        }

        this.props.setIsSelectAll(false);
        this.resetSelectedAndCloseDialogAndRefreshList("brandDialog");
    };

    handleSetCornerOnProducts = (cornerId) => {
        const params = this.getParamsForHandlingActionToSelected('corner', cornerId);

        if (!this.state.isTotalSelected) {
            params['productList'] =  this.props.selected.map((item) => item['@id']);

            this.props.massUpdate(params);
        } else if ("" !== cornerId.trim()) {
            this.fetchHandlingActionSelected(params);
        }

        this.props.setIsSelectAll(false);
        this.resetSelectedAndCloseDialogAndRefreshList("setProductOnCornerDialog");
    };

    handleDialog = (dialogName) => {
        if ('addCategoriesDialog' === dialogName && !this.state.allCategoriesFetched) {
            fetch(`/categories?draft=false&pagination=false`).then(
                response => {
                    response.json().then(retrieved => {
                        this.setState({ allCategories: retrieved['hydra:member'], allCategoriesFetched: true })
                    });
                }
            );
        } else if ('genderDialog' === dialogName && !this.state.allGendersFetched) {
            fetch(`/genders?draft=false&pagination=false`).then(
                response => {
                    response.json().then(retrieved => {
                        this.setState({ allGenders: retrieved['hydra:member'], allGendersFetched: true })
                    });
                }
            );
        } else if ('brandDialog' === dialogName && !this.state.allBrandsFetched) {
            fetch(`/brands?pagination=false`).then(
                response => {
                    response.json().then(retrieved => {
                        this.setState({ allBrands: retrieved['hydra:member'], allBrandsFetched: true })
                    });
                }
            );
        }

        this.setState({
            [dialogName]: !this.state[dialogName],
            searchCategories: '',
            categoriesToAdd: []
        });
    };

    getCategoryChildren = (parentId, childToFindId, indexChildToDelete = -1) => {
        const categoriesWithAllNodes = this.getCategoriesWithAllNodes(this.props.categoryRetrieved)

        categoriesWithAllNodes && categoriesWithAllNodes.filter(cat => cat.parent && cat.parent.id === parentId).forEach(child => {
            if (child.id === childToFindId) {
                indexChildToDelete = this.state.selectedFilters.indexOf(child)
            } else {
                indexChildToDelete = this.getCategoryChildren(child.id, childToFindId, indexChildToDelete)
            }
        })

        return indexChildToDelete
    }

    displayDeleteProductsAction = () => {
        if (this.props.offer === marketPlaceOffer && hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT)) {
            return (
                this.renderMenuItems("deleteProducts", "retailer.product.list.action.delete.products", [this.props.selected], "retailer.product.list.action.delete.products")
            );
        }
    };

    renderActions = () => {
        if (!(hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) || hasAuthorization(this.props.authorizations, Authorizations.STOCK_EASY_ACCESS))) {
            return;
        }

        const {classes} = this.props;
        const {allCorners} = this.state;

        return (
            <div className={classes.actionsContainer}>
                {this.renderMassUpdateStockEasy()}
                {this.renderSelectableActions()}
                {this.renderAddCategories()}
                {this.renderDeleteCategories()}
                {this.renderEditGender()}
                {this.renderEditBrand()}
                {allCorners && this.renderSetCorner()}
                {this.renderDeleteProducts()}
                {this.renderCreateProductButton()}
                {this.renderManageStockButton()}
                {this.renderExportButton()}
            </div>
        );
    };

    renderExportButton() {
        const { classes, exportLoading, exportSuccess } = this.props;

        const handleSubmit = () => {
            this.props.export(this.props.retailer, this.props.match.params.params)
        };

        if (this.props.loading || UserAgentUtils.isMobile()) {
            return null;
        }

        if (exportLoading) {
            return (
                <BeatLoader
                    sizeUnit={"px"}
                    size={12}
                    color={primaryColor}
                    loading={true}
                />
            )
        }

        return <>
            <Tooltip title={this.props.intl.formatMessage({id: "retailer.product.list.export"})}>
                <IconButton
                    className={classes.exportButton}
                    onClick={handleSubmit}
                    color="primary"
                    size="large">
                    <GetApp/>
                </IconButton>
            </Tooltip>
            {exportSuccess && <FormattedMessage id={"retailer.product.list.export.success"} />}
        </>;
    }

    renderCreateProductButton() {
        const {classes, retailer} = this.props;

        if (this.props.offer === stockEasyOffer && retailer) {
            return (
                <Button
                    href={`/stockeasy/retailers/${retailer.id}/product/create`}
                    simple
                    color={"primary"}
                    variant={"outlined"}
                    className={classes.addProduct}
                >
                    <FormattedMessage id={"retailer.product.list.action.add.product"}/>
                </Button>
            )
        }
    }

    renderManageStockButton() {
        const {classes, retailer} = this.props;

        if (this.props.offer === stockEasyOffer && retailer) {
            return (
                <Button
                    href={`/stockeasy/retailers/${retailer.id}/product/stock`}
                    simple
                    color={"primary"}
                    variant={"outlined"}
                    className={classes.addProduct}
                >
                    <FormattedMessage id={"retailer.product.list.action.manage.stock"}/>
                </Button>
            )
        }
    }

    renderMenuItems = (value, message) => {
        return <MenuItem value={value} key={value}>
            <FormattedMessage id={message}/>
        </MenuItem>
    };

    handleShowChildren = (categoryId) => {
        const {showChildren} = this.state;

        showChildren[categoryId] = !showChildren[categoryId];

        this.setState({showChildren});
    };

    handleDeleteSelectedCategory = (category) => {
        const {categoriesToAdd} = this.state;

        let index = categoriesToAdd.indexOf(category);

        categoriesToAdd.splice(index, 1);
        this.setState({categoriesToAdd});
    };

    getCategoriesWithAllNodes = (categoryRetrieved) => {
        const categoriesWithAllNodes = []
        const alreadyListedCategory = []
        categoryRetrieved.forEach(category => {
            if (alreadyListedCategory.indexOf(category.id) < 0) {
                categoriesWithAllNodes.push(category)
                alreadyListedCategory.push(category.id)
            }

            let parent = category.parent
            while (parent) {
                if (alreadyListedCategory.indexOf(parent.id) < 0) {
                    categoriesWithAllNodes.push(parent)
                    alreadyListedCategory.push(parent.id)
                }

                parent = parent.parent
            }
        })

        return categoriesWithAllNodes
    }

    hasChildren = (parentId) => {
        const categoriesWithAllNodes = this.getCategoriesWithAllNodes(this.state.allCategories)

        if (categoriesWithAllNodes && categoriesWithAllNodes.filter(cat => cat.parent && cat.parent.id === parentId).length > 0) {
            return true
        }
    }

    renderCategoryChildren = (categories, checkChildren = false) => {
        const {classes} = this.props;
        const {categoriesToAdd, showChildren, allCategories} = this.state;

        if (allCategories && categories.length > 0) {
            return categories.map(category => {
                let hasChildren = this.hasChildren(category.id);
                let isChecked = categoriesToAdd.indexOf(category) !== -1 || checkChildren;

                return (
                    <div key={category.id}>
                        <ListItem className={classes.categorieListItem} button={hasChildren}>
                            <Checkbox
                                color="primary"
                                onChange={() => this.handleCheckCategoryAdd(category, categoriesToAdd)}
                                checked={isChecked}
                                classes={{checked: classes.checked}}
                                disabled={hasChildren}
                            />
                            <ListItemText
                                className={classes.categoryNameListItem + ' '+((showChildren[category.id] || isChecked) ? classes.categoryNameListItemSelected : null)}
                                primary={getTranslation(category).name}
                                onClick={() => hasChildren ? this.handleShowChildren(category.id) : this.handleCheckCategoryAdd(category, categoriesToAdd)}
                            />
                            {hasChildren && (showChildren[category.id] ? <KeyboardArrowDown/> : <KeyboardArrowRight/>)}
                        </ListItem>
                        <Collapse className={classes.childrenCategories} in={hasChildren && showChildren[category.id]} timeout="auto" unmountOnExit>
                            {this.renderCategoryChildren(allCategories.filter(cat => cat.parent !== null && cat.parent.id === category.id))}
                        </Collapse>
                    </div>
                );
            })
        } else {
            return <div className={classes.tableCellNoResult}><FormattedMessage id={"retailer.list.table.no_result"}/>
            </div>
        }
    };

    renderAddCategories = () => {
        const {classes} = this.props;
        const {categoriesToAdd, addCategoriesDialog, searchCategories, allCategoriesFetched} = this.state;
        const categoryRetrieved = this.state.allCategories;

        return (
            <Dialog
                scroll="paper"
                open={addCategoriesDialog}
                onClose={() => {
                    this.handleDialog('addCategoriesDialog');
                }}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.dialog.title.add.categories"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogContent}>
                    { allCategoriesFetched ? (
                        <>
                            <Input
                                placeholder={this.props.intl.formatMessage({id: "retailer.product.list.action.add.categories.find"})}
                                onChange={(e) => this.setState({searchCategories: e.target.value})}
                                className={classes.dialogSearch}
                                autoFocus
                                disableUnderline
                            />
                            <Divider/>
                            <List disablePadding className={classes.dialogContentChild}>
                                {searchCategories.length > 0 ?
                                    this.renderCategoryChildren(categoryRetrieved.filter(cat => getTranslation(cat).name.toLowerCase().includes(searchCategories)))
                                    :
                                    this.renderCategoryChildren(categoryRetrieved.filter(cat => cat.parent === null))
                                }
                            </List>
                        </>
                    ) : (
                        <div className={classes.dialogContentLoader}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={primaryColor}
                                loading={true}
                            />
                        </div>
                    )}
                </DialogContent>
                { allCategoriesFetched && (
                    <>
                        <Divider/>
                        <div>
                            {categoriesToAdd.length > 0 && categoriesToAdd.map(category => {
                                return <Chip
                                    key={category.id}
                                    label={getTranslation(category).name || category.value}
                                    color="primary"
                                    onDelete={() => this.handleDeleteSelectedCategory(category)}
                                    className={classes.filterSelectedItem}
                                />
                            })}
                        </div>
                        <Divider/>
                        <DialogActions>
                            <Button color={"info"} simple onClick={() => this.handleDialog('addCategoriesDialog')}>
                                <ArrowBackIos/>
                                <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                            </Button>
                            <Button
                                disabled={!categoriesToAdd.length}
                                round
                                onClick={() => this.handleActionToSelected('categoryAdd', this.state.categoriesToAdd, 'addCategoriesDialog')}
                                color="primary"
                            >
                                <FormattedMessage id={"retailer.product.list.dialog.add"}/>
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        );
    };

    renderDeleteProducts = () => {
        const {classes} = this.props;
        const {deleteProductsDialog} = this.state;

        return (
            <Dialog
                scroll="paper"
                open={deleteProductsDialog}
                onClose={() => this.handleDialog('deleteProductsDialog')}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.delete.products"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogTitle}>
                    <FormattedHTMLMessage id={"retailer.product.list.action.delete.products.notice"}/>
                </DialogContent>
                <DialogActions>
                    <Button color={"info"} simple onClick={() => this.handleDialog('deleteProductsDialog')}>
                        <ArrowBackIos/>
                        <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                    </Button>
                    <Button
                        round
                        onClick={() =>  this.handleActionToSelected('productDelete', null, 'deleteProductsDialog')}
                        color="danger"
                    >
                        <Delete/>
                        <FormattedMessage id={"retailer.product.list.dialog.action.delete"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderDeleteCategories = () => {
        const {classes, selected} = this.props;
        const {categoriesToDelete, deleteCategoriesDialog} = this.state;

        return (
            <Dialog
                scroll="paper"
                open={deleteCategoriesDialog}
                onClose={() => this.handleDialog('deleteCategoriesDialog')}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.delete.categories"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <List disablePadding>
                        {this.getCategoriesByProduct(selected).map(cat => {
                            return (
                                <ListItem key={cat.id} className={classes.categorieListItem}>
                                    <Checkbox
                                        color="primary"
                                        onChange={() => this.handleCheckCategoryDelete(cat, categoriesToDelete)}
                                        checked={categoriesToDelete.indexOf(cat) === -1}
                                        classes={{checked: classes.checked}}
                                    />
                                    <ListItemText primary={getTranslation(cat).name}/>
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color={"info"} simple onClick={() => this.handleDialog('deleteCategoriesDialog')}>
                        <ArrowBackIos/>
                        <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                    </Button>
                    <Button
                        disabled={!categoriesToDelete.length}
                        round
                        onClick={() => this.handleActionToSelected('categoryDelete', this.state.categoriesToDelete, 'deleteCategoriesDialog')}
                        color="primary"
                    >
                        <FormattedMessage id={"retailer.product.list.dialog.submit"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    renderEditGender = () => {
        const {classes} = this.props;
        const {genderDialog, genderSelected, allGendersFetched} = this.state;
        const genderRetrieved = this.state.allGenders;

        return (
            <Dialog
                open={genderDialog}
                scroll="paper"
                onClose={() => this.handleDialog("genderDialog")}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.gender"}/>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    { allGendersFetched ? (
                        <Select
                            value={genderSelected.length > 0 ? genderSelected : (genderRetrieved.length > 0 ? genderRetrieved[0]['@id'] : '')}
                            onChange={(event) => this.setState({genderSelected: event.target.value})}
                            input={<InputBase value={this.state.genderSelected}/>}
                            IconComponent={UnfoldMore}
                            className={classes.inviteSelectField}
                            variant="outlined"
                        >
                            {genderRetrieved.map(item => {
                                return <MenuItem key={item.id} value={item['@id']}>{getTranslation(item).value}</MenuItem>
                            })}
                        </Select>
                    ) : (
                        <div className={classes.dialogContentLoader}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={primaryColor}
                                loading={true}
                            />
                        </div>
                    )}
                </DialogContent>
                { allGendersFetched && (
                    <DialogActions>
                        <Button color={"info"} simple onClick={() => this.handleDialog('genderDialog')}>
                            <ArrowBackIos/>
                            <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                        </Button>
                        <Button
                            round
                            onClick={() => this.handleActionToSelected('gender', genderSelected, "genderDialog")}
                            color="primary"
                        >
                            <FormattedMessage id={"retailer.product.list.dialog.submit"}/>
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    };

    renderEditBrand = () => {
        const {classes} = this.props;
        const {brandDialog, brandSelected, searchBrand, allBrandsFetched} = this.state;
        const brandRetrieved = this.state.allBrands;

        let brandArray = searchBrand.length > 0 ? brandRetrieved.filter(brand => brand.name.toLowerCase().includes(searchBrand)) : brandRetrieved;

        return (
            <Dialog
                open={brandDialog}
                scroll="paper"
                onClose={() => {
                    this.handleDialog("brandDialog");
                    this.setState({searchBrand: ''});
                }}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.brand"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogContent}>
                    { allBrandsFetched ? (
                        <>
                            <Input
                                placeholder={this.props.intl.formatMessage({id: "retailer.product.list.action.brand.find"})}
                                onChange={(e) => this.setState({searchBrand: e.target.value})}
                                className={classes.dialogSearch}
                                autoFocus
                                disableUnderline
                            />
                            <Divider/>
                            {brandArray.length > 0 ?
                                <RadioGroup
                                    ref={null}
                                    aria-label="Brand"
                                    name="brand"
                                    value={brandSelected.length > 0 ? brandSelected : brandArray[0]["@id"]}
                                    onChange={(event) => this.setState({brandSelected: event.target.value})}
                                    className={classes.dialogContentChild}
                                >
                                    {brandArray.map(brand => (
                                        <FormControlLabel value={brand["@id"]} key={brand.id} control={<Radio color="primary"/>}
                                                          label={brand.name}/>
                                    ))}
                                </RadioGroup>
                                :
                                <div className={classes.tableCellNoResult}><FormattedMessage
                                    id={"retailer.list.table.no_result"}/></div>
                            }
                            </>
                        ) : (
                            <div className={classes.dialogContentLoader}>
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={primaryColor}
                                    loading={true}
                                />
                            </div>
                        )}
                </DialogContent>
                { allBrandsFetched && (
                    <DialogActions>
                        <Button simple onClick={() => {
                            this.handleDialog("brandDialog");
                            this.setState({searchBrand: ''});
                        }} color="info">
                            <ArrowBackIos/>
                            <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                        </Button>
                        <Button
                            round
                            onClick={() => this.handleEditBrandToSelected(brandSelected)}
                            color="primary"
                        >
                            <FormattedMessage id={"retailer.product.list.dialog.submit"}/>
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    };

    renderSetCorner() {
        const {classes} = this.props;
        const {allCorners, setProductOnCornerDialog, searchCorner, cornerSelected} = this.state;

        let corners = allCorners;
        if (!!searchCorner) {
            corners = corners.filter(corner => getTranslation(corner).name.toLowerCase().includes(searchCorner));
        }

        return (
            <Dialog
                open={setProductOnCornerDialog}
                scroll="paper"
                onClose={() => {
                    this.handleDialog("setProductOnCornerDialog");
                    this.setState({searchCorner: ''});
                }}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle}>
                    <FormattedMessage id={"retailer.product.list.action.corner"}/>
                </DialogTitle>
                <Divider/>
                <DialogContent className={classes.dialogContent}>
                    <Input
                        placeholder={this.props.intl.formatMessage({id: "retailer.product.list.action.corner.find"})}
                        onChange={(e) => this.setState({searchCorner: e.target.value})}
                        className={classes.dialogSearch}
                        autoFocus
                        disableUnderline
                    />
                    <Divider/>
                    {corners.length > 0 ?
                        <RadioGroup
                            ref={null}
                            aria-label="Corner"
                            name="corner"
                            value={cornerSelected}
                            onChange={(event) => this.setState({cornerSelected: event.target.value})}
                            className={classes.dialogContentChild}
                        >
                            {corners.map(corner => (
                                <FormControlLabel
                                    value={corner["@id"]}
                                    key={corner.id}
                                    control={<Radio color="primary"/>}
                                    label={getTranslation(corner).name}
                                />
                            ))}
                        </RadioGroup>
                        :
                        <div className={classes.tableCellNoResult}>
                            <FormattedMessage id={"retailer.list.table.no_result"}/>
                        </div>
                    }

                </DialogContent>
                <DialogActions>
                    <Button simple onClick={() => {
                        this.handleDialog("setProductOnCornerDialog");
                        this.setState({searchCorner: ''});
                    }} color="info">
                        <ArrowBackIos/>
                        <FormattedMessage id={"retailer.product.list.dialog.cancel"}/>
                    </Button>
                    <Button
                        round
                        onClick={() => this.handleSetCornerOnProducts(this.state.cornerSelected)}
                        color="primary"
                    >
                        <FormattedMessage id={"retailer.product.list.dialog.submit"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderSelectTotal() {
        const {classes, retrieved,isSelectAll, selected} = this.props;
        const {isTotalSelected, itemsPerPage, totalProductSelected} = this.state;

        if (!retrieved || !isSelectAll) {
            return null
        }

        const editableProducts = this.removeEikowAndFeedProductsFromList(retrieved['hydra:member'])

        // if all possible editable product are selected
        if (selected.length === editableProducts.length && retrieved['hydra:member'].length === retrieved['hydra:totalItems']) {
            return null;
        }

        return (
            <GridContainer className={classes.selectTotalContainer}>
                <TotalSelectedMessage
                    offer={this.props.offer}
                    totalProductSelected={totalProductSelected}
                    isTotalSelected={isTotalSelected}
                />
                <MaterialUiButton
                    color="primary"
                    className={classes.selectTotalButton}
                    onClick={(event) => {
                        event.stopPropagation();
                        if (!isTotalSelected) {
                            this.setState({
                                isTotalSelected: true,
                                totalProductSelected: retrieved["hydra:totalItems"]
                            })
                        } else {
                            this.props.setSelected([]);
                            this.props.setIsSelectAll(false);
                            this.setState({
                                isTotalSelected: false,
                                totalProductSelected: parseInt(itemsPerPage)
                            })
                        }
                    }}
                >
                <TotalSelectedActionMessage
                    isTotalSelected={isTotalSelected}
                    totalItems={retrieved["hydra:totalItems"]}
                    offer={this.props.offer}
                />
                </MaterialUiButton>
            </GridContainer>
        )
    }

    renderNotification = () => {
        const {massUpdateSuccess} = this.props;
        const {showNotification} = this.state;

        if (massUpdateSuccess) {
            return (
                <Snackbar
                    open={showNotification}
                    close
                    closeNotification={() => this.setState({showNotification: false})}
                    place={"bl"}
                    color={"success"}
                    icon={function () {
                        return <NotificationsActive/>
                    }}
                    message={<FormattedMessage id={"retailer.product.list.notifications.list.updated"}/>}
                />
            );
        }
    };

    renderLoadingBar = () => {
        const {loading, massUpdateLoading, updateLoadingVariation, retrieved, classes} = this.props;

        return (
            <div>
                {!retrieved || massUpdateLoading ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={loading || updateLoadingVariation || massUpdateLoading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={loading || updateLoadingVariation || massUpdateLoading}
                        />
                    </div>
                }
            </div>
        )
    };

    updateStoreParameters(params) {
        let formattedParams = paramsToObject(params);

        this.setState(formattedParams);

        if (formattedParams.length === 4) {
            this.setState({selectedFilters: []})
        }
    }

    handlePageReset = (params) => {
        params = params.split("&");

        params.map((param, key) => {
            if (param.search("page") !== -1) {
                params[key] = "page=1";
            }

            return true;
        });

        return params;
    };

    getParameterNameAndPropertyValue = (params, paramName) => {
        let values = "";
        params = params.split("&");
        params.forEach((param) => {
            if (param.search(paramName) !== -1) {
                values = param;
            }
        });
        return values;
    };

    resetProductsSelection = () => {
        this.props.setIsSelectAll(false);
        this.props.setSelected([]);
        this.setState({
            isTotalSelected: false,
            totalProductSelected: null,
        });
    }

    removeEikowAndFeedProductsFromList = (productList) => {
        return productList.filter(product => product.eikow === false);
    }

    computeIsCheckedByProduct = (product) => {
        return this.props.selected.some(selected => selected.id === product.id);
    }

    render() {
        const {itemsPerPage} = this.state;
        const {classes, retrieved, intl} = this.props;
        const currentPage = this.getParameterNameAndPropertyValue(decodeURIComponent(this.props.match.params.params), "page");
        const currentOrderBy = this.getParameterNameAndPropertyValue(decodeURIComponent(this.props.match.params.params), "order");

        return (
            <div className={classes.containerWithSidebar}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Card className={classes.card}>
                                <CardHeader color="info">
                                    <GridContainer>
                                        <GridItem xs={12} sm={8} className={classes.titleContainer}>
                                            <h3><FormattedMessage id={"retailer.product.list.title"}/></h3>
                                            <Tooltip title={<FormattedMessage id={"retailer.product.icon.list.detailed"}/>}>
                                                <ViewList className={classes.showVariationIcon}
                                                      onClick={() => this.props.history.push(`${this.state.baseParamsUrl + this.props.match.params.id}/products/variations/${encodeURIComponent(`retailer.id=${decodeURIComponent(this.props.match.params.id).replace("/retailers/", "")}&order[product.id]=asc&itemsPerPage=${this.state.itemsPerPage}&page=1`)}`)}/>
                                            </Tooltip>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} className={classes.mainActionsContainer}>
                                            <TextField
                                                id="search-input"
                                                placeholder={intl.formatMessage({id: "retailer.product.list.filter.search.placeholder"})}
                                                onChange={(event) => this.handleSearch(event)}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (<Search color={"disabled"}/>),
                                                }}
                                                value={this.state['translations.name']}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>

                                    {/*Filters menu*/}
                                    <FilterMenu
                                        classes={classes}
                                        brandList={this.props.brandList}
                                        brandRetrieved={this.props.brandRetrieved}
                                        genderList={this.props.genderList}
                                        genderRetrieved={this.props.genderRetrieved}
                                        retailerOptionValuesList={this.state.retailerOptionValuesList}
                                        categoryList={this.props.categoryList}
                                        categoryRetrieved={this.props.categoryRetrieved}
                                        offer={this.props.offer}
                                        match={this.props.match}
                                        history={this.props.history}
                                        baseParamsUrl={this.state.baseParamsUrl}
                                        hasChildren={this.hasChildren}
                                        itemsPerPage={this.state.itemsPerPage}
                                        handlePageReset={this.handlePageReset}
                                        getCategoryChildren={this.getCategoryChildren}
                                        getCategoriesWithAllNodes={this.getCategoriesWithAllNodes}
                                        channels={this.props.currentOrganization?.eshopConfig?.channels}
                                    />

                                    {/*Actions, download, create and pagination*/}
                                    <GridContainer>

                                        {/*Actions list*/}
                                        <GridItem xs={12} sm={7} className={classes.action}>
                                            {this.renderActions()}
                                        </GridItem>

                                        {/*Pagination*/}
                                        <GridItem xs={12} sm={5} className={classes.totalContainer}>
                                            <span className={classes.totalSpan}>
                                              {retrieved ? intl.formatMessage({id: "retailer.product.list.label.totalProduct"}) + retrieved["hydra:totalItems"] : null}
                                            </span>
                                            <ItemsPerPageSelect
                                                prefixUrl={`${this.state.baseParamsUrl + this.props.match.params.id}/products/`}
                                                history={this.props.history}
                                                match={this.props.match}
                                                defaultItemsPerPage={"50"}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    {/*Table*/}
                                    <div className={classes.tableWrapper}>
                                        {this.renderSelectTotal()}
                                        <Table padding={retrieved && retrieved["hydra:member"].length > 0 && retrieved["hydra:totalItems"]? "none" : "normal"}>
                                            <TableHead
                                                state={this.state}  //todo: handle only sort values, i.e: order[id] = asc, etc.
                                                onRequestSort={this.handleRequestSort}
                                                onSelectAllClick={this.handleSelectAllClick}
                                                isRetrievedFinished={retrieved && retrieved["hydra:member"].length > 0}
                                                offer={this.props.offer}
                                            />
                                            <TableBody>
                                                {!this.props.massUpdateLoading && (retrieved && retrieved["hydra:member"].length > 0) &&
                                                retrieved["hydra:member"].map(product => {
                                                    return (
                                                        <ProductRow
                                                            product={product}
                                                            classes={classes}
                                                            currentPage={currentPage}
                                                            currentOrderBy={currentOrderBy}
                                                            baseParamsUrl={this.state.baseParamsUrl}
                                                            key={product.id}
                                                            history={this.props.history}
                                                            match={this.props.match}
                                                            offer={this.props.offer}
                                                            isChecked={this.computeIsCheckedByProduct(product)}
                                                        />
                                                    );
                                                })
                                                }
                                                {retrieved && retrieved["hydra:member"].length === 0 &&
                                                <TableRow>
                                                    <TableCell colSpan={9} className={classes.tableCellNoResult}>
                                                        <FormattedMessage id={"feed.list.table.no_result"}/>
                                                    </TableCell>
                                                </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                </CardBody>

                                {/*Pagination*/}
                                <CardFooter center>
                                    {!this.props.massUpdateLoading &&
                                        <PaginationContainer
                                            itemsPerPage={this.state.itemsPerPage}
                                            urlParams={this.props.match.params.params}
                                            retrieved={this.props.retrieved}
                                            prefix={`${this.state.baseParamsUrl + this.props.match.params.id}`}
                                        />
                                    }
                                </CardFooter>
                                {this.renderLoadingBar()}
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                {this.renderNotification()}
            </div>
        );
    }

    renderMassUpdateStockEasy() {
        if (UserAgentUtils.isMobile()) {
            return;
        }

        const {classes} = this.props;
        const isActionsDisable = (!this.state.isTotalSelected && this.props.selected.length < 1) || this.props.massUpdateLoading || this.state.loadingAllProduct;

        if (this.props.offer === stockEasyOffer) {
            const tooltip = isActionsDisable ?
                'retailer.product.list.action.mass_product_status_toggle_disable' :
                'retailer.product.list.action.mass_product_status_toggle_explanation';

            return (
                <div className={classes.disableElementsContainer}>
                    <span className={classes.myOffers}>
                        <FormattedMessage  id={"retailer.product.list.action.products"} />
                    </span>
                    <Tooltip title={<FormattedMessage id={tooltip}/>}>
                        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                            <Button
                                color={"danger"}
                                type="submit"
                                disabled={isActionsDisable}
                                onClick={() => this.handleEditStatusToSelected(false)}
                            >
                                <FormattedMessage id={"retailer.product.list.action.stockeasy.disable"} />
                            </Button>
                            <Button
                                color={"success"}
                                type="submit"
                                disabled={isActionsDisable}
                                onClick={() => this.handleEditStatusToSelected(true)}
                            >
                                <FormattedMessage id={"retailer.product.list.action.stockeasy.activate"} />
                            </Button>
                        </ButtonGroup>
                    </Tooltip>
                </div>
            );
        }
    }
    renderSelectableActions() {
        if (UserAgentUtils.isMobile()) {
            return;
        }
        const {classes, selected} = this.props;
        const {action} = this.state;
        const isActionsDisable = (!this.state.isTotalSelected && this.props.selected.length < 1) || this.props.massUpdateLoading || this.state.loadingAllProduct;
        const isAllowed = this.props.authorizations && hasAuthorization(this.props.authorizations, Authorizations.MARKETPLACE_PRODUCT_MANAGEMENT) || this.props.offer === stockEasyOffer;

        let availableActions = {};
        if (this.props.offer === stockEasyOffer) {
            availableActions = {
                'setCornerOnProducts': 'retailer.product.list.action.corner',
            }
        } else {
            availableActions = {
                'active': 'retailer.product.list.action.offer.activate',
                'inactive': 'retailer.product.list.action.offer.disable',
                'addCategories': 'retailer.product.list.action.add.categories',
                'deleteCategories': 'retailer.product.list.action.delete.categories',
                'editGender': 'retailer.product.list.action.gender',
                'editBrand': 'retailer.product.list.action.brand',
                'setCornerOnProducts': 'retailer.product.list.action.corner',
            }
        }

        if (isAllowed) {
            return (
                <FormControl
                    variant="outlined"
                    disabled={selected.length > 0}
                    className={classes.actionSelect}
                >
                    <Select
                        variant="standard"
                        value={action}
                        onChange={this.handleChangeAction}
                        input={<OutlinedInput value="actions" name="actions"/>}
                        IconComponent={UnfoldMore}
                        displayEmpty={true}
                        className={classes.customSelectActions}
                        autoWidth={true}
                        disabled={isActionsDisable}>
                        <MenuItem value="" disabled>Actions</MenuItem>
                        { Object.keys(availableActions).map(key => {

                            return this.renderMenuItems(key, availableActions[key])
                        })}
                        {this.displayDeleteProductsAction()}
                    </Select>
                </FormControl>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        retrieved: state.product.list.retrieved,
        brandRetrieved: state.brand.list.retrieved,
        categoryRetrieved: state.category.list.retrieved,
        genderRetrieved: state.gender.list.retrieved,
        loading: state.product.list.loading,
        exportLoading: state.product.list.exportLoading,
        exportSuccess: state.product.list.exportSuccess,
        massUpdateLoading: state.product.massUpdate.massUpdateLoading,
        massUpdateSuccess: state.product.massUpdate.massUpdateSuccess,
        massUpdateError: state.product.massUpdate.massUpdateError,
        authorizations: state.authentication.authorizations,
        member: state.authentication.member,
        itemsPerPage: state.itemsPerPage.itemsPerPage,
        retailer: state.retailer.show.retrieved,
        selected: state.product.selection.selected,
        isSelectAll: state.product.selection.isSelectAll,
        currentOrganization: state.currentOrganization.retrieved
    };
};

const mapDispatchToProps = dispatch => ({
    list: (params) => dispatch(list(params)),
    export: (retailer, params) => dispatch(downloadExport(retailer, params)),
    reset: () => dispatch(reset()),
    brandList: params => dispatch(brandList(params)),
    categoryList: params => dispatch(categoryList(params)),
    genderList: params => dispatch(genderList(params)),
    massUpdate: (params, values) => dispatch(massUpdate(params, values)),
    massUpdateReset: eventSource => dispatch(massUpdateReset(eventSource)),
    retailerOptionValuesList: params => dispatch(retailerOptionValuesList(params)),
    handleSelectProduct: (product, selected) => dispatch(handleSelectProduct(product, selected)),
    setSelected: (selected) => dispatch(setSelected(selected)),
    setIsSelectAll: (isSelectAll) => dispatch(setIsSelectAll(isSelectAll)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(listStyle)(injectIntl(ProductsTable)));
