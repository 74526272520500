import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from 'react-spinners';
import Select from 'react-select'
import filter from "lodash/filter";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import {validate as uuidValidate} from 'uuid';

// @mui/material components
import {
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Fab,
    Popover,
    Stepper,
    Step,
    StepButton,
    Tooltip,
} from "@mui/material";

// @mui/icons-material
import {
    Add,
    ArrowBackIos,
    ArrowForwardIos,
    Delete,
    ExpandMore,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Remove,
    Settings,
} from "@mui/icons-material";

// core components
import Button from "components/button/button";
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

// styles
import formShowStyle from "assets/jss/views/feed/mapper/formStyle";
import {primaryColor, dangerColor} from "assets/jss/main";
import "@webscopeio/react-textarea-autocomplete/style.css";

// actions
import {retrieve} from "actions/feed/update";
import {del} from "actions/feed/mapper/delete";

// helpers
import {capitalize} from "utils/capitalize";
import {fetch} from "../../../../../utils/dataAccess";
import {withStyles} from "@mui/styles";
import {getTranslation} from 'domain/helpers/translations';
import {
    getStockFeedMapperFields,
    getInventoryMapperFieldsNamesFromConfiguration,
    MapperFields,
    MapperPrefixCode
} from "utils/mapperFields";

// copy of form.js. We will simplify this when we redo the mapper view
class StockForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0,
            isFirstTimeMapper: false,
            expertModePosition: null,
            expertModePropertySelected: null,
            expertModePropertyValue: null,
            expertModeEntries: [{ name: "", value: "" }],
            expressionLanguageSuccess: null,
            expressionLanguageError: null,
            expressionLanguageLoading: null,
            options: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.props.retrieve(decodeURIComponent(this.props.match.params.fid));
    }

    del = () => {
        if (window.confirm("Are you sure you want to delete this item?"))
            this.props.del(this.props.mapperRetrieved);
    };

    handleStep = step => () => {
        const {initialValues, intl} = this.props;
        const {activeStep} = this.state;

        if (activeStep === 0 && (initialValues && !initialValues.configuration.sku.value)) {
            alert(intl.formatMessage({id: "mapper.step.alert.requiredSku"}));

            return false;
        }
        this.setState({activeStep: step}, () => {
            if (this.state.activeStep === 4) {
                this.setState({isFirstTimeMapper: false})
            }
        })
    };

    getFeedHeaders(feedProperties, propertyName, isExpertMode = false) {
        let options = [];

        if (isExpertMode === false) {
            options.push({
                value: "none",
                label: this.props.intl.formatMessage({id: "mapper.form.options.none"}),
                input: propertyName
            });
        }

        feedProperties && feedProperties.forEach((item) => (
            options.push({
                value: item,
                label: capitalize(item),
                input: propertyName
            })
        ));

        return options;
    }

    handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.expertModeEntries];
        list[index][name] = value;
        this.setState({expertModeEntries: list});
    };

    handleRemoveClick = index => {
        const list = [...this.state.expertModeEntries];
        list.splice(index, 1);
        this.setState({expertModeEntries: list});
    };

    handleAddClick = () => {
        this.setState({expertModeEntries: [...this.state.expertModeEntries, { name: "", value: "" }]});
    };

    checkExpressionLanguage = (expression, entries) => {
        this.setState({expressionLanguageLoading: true});
        let finalEntries = {};
        entries.forEach((e) => finalEntries[e.name] = e.value);
        fetch("/expressions-language/check", {method: "POST", body: JSON.stringify({'expression': expression, 'entries': finalEntries})})
            .then(response => {
                response.json().then(retrieved => {
                    this.setState({
                        expressionLanguageSuccess: retrieved.value,
                        expressionLanguageError: retrieved.error,
                        expressionLanguageLoading: false
                    });
                });
            });
    }

    renderField = data => {
        const {classes} = this.props;
        data.input.className = "";

        const isInvalid = data.meta.touched && !!data.meta.error;
        if (isInvalid) {
            data.input.className += " is-invalid";
            data.input["aria-invalid"] = true;
        }

        if (this.props.error && data.meta.touched && !data.meta.error) {
            data.input.className += " is-valid";
        }

        if (data.input.value === "") {
            data.input.value = data.defaultValue ? data.defaultValue : "";
        }
        const lineColor = data.defaultValue ? primaryColor : dangerColor;

        let options = this.getFeedHeaders(data.options, data.input.name);

        let isCombinedValue = data.input.name === "categories" && data.defaultValue && (data.defaultValue.split(">").length > 1)

        const expertModePropertiesBlackList = ["sku", "parentSku", "upc", "barcode"];

        return (
            <Card className={classes.fieldContainer}>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={4} sm={4}>
                            <label
                                htmlFor={`mapper_${data.input.name}`}
                                className={classes.fieldMappingLabel}
                            >
                                {data.label}
                            </label>
                            {data.subTitle &&
                                <span className={classes.fieldMappingSubTitle}> {data.subTitle}</span>
                            }
                        </GridItem>
                        <GridItem className={classes.lineContainer} xs={2} sm={4}>
                            {data.defaultValue ?
                                <FormattedMessage id={'mapper.label.configured'}/> :
                                <FormattedMessage id={'mapper.label.notConfigured'}/>
                            }
                            <div className={classes.line} style={{borderBottom: '2px solid' + lineColor}}>
                                <div>
                                    <div className={classes.round} style={{background: lineColor}}/>
                                    <div className={classes.round} style={{background: lineColor}}/>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={6} sm={4} className={classes.spaceEvenlyGrid}>
                            {expertModePropertiesBlackList.includes(data.input.name) === false &&
                                <GridItem xs={3} sm={3}>
                                    <div>
                                        <Fab className={classes.listButton}>
                                            <Settings onClick={(e) => {
                                                this.openExpertMode(e.currentTarget, data)
                                                }}
                                            />
                                        </Fab>
                                    </div>
                                </GridItem>
                            }
                            <GridItem xs={3} sm={3}>
                                <div>
                                    <Fab onClick={() => this.listFocused(data.input.name)}
                                         className={classes.listButton}
                                         color="default">
                                        {!data.selectedLabel ?
                                            <KeyboardArrowDown/>
                                            :
                                            <KeyboardArrowUp/>
                                        }
                                    </Fab>
                                </div>
                            </GridItem>
                            {data.multiChoice && data.defaultValue && !data.selectedLabel &&
                                <GridItem xs={3} sm={3}>
                                    <Fab onClick={() => this.openCombinationPopup()} className={classes.listButton}>
                                        <Add />
                                    </Fab>
                                </GridItem>
                            }
                            <GridItem xs={9} sm={9}>
                                {data.selectedLabel &&
                                    <Select
                                        options={options}
                                        placeholder={<FormattedMessage id={'mapper.select.placeholder'}/>}
                                        id={`mapper_${data.input.name}`}
                                        onChange={this.handleChange}
                                        defaultMenuIsOpen={true}
                                        className={classes.selectField}
                                        autoFocus={true}
                                        onBlur={() => this.onBlurSelect(data.input.name)}
                                    />
                                }
                                {!data.selectedLabel && !isCombinedValue &&
                                    <b className={classes.defaultValueText}>
                                        {data.defaultValue && (data.expert === '1' ?
                                            <FormattedMessage id={'mapper.label.expert'}/>
                                            : capitalize(data.defaultValue))}
                                    </b>
                                }
                                {!data.selectedLabel && isCombinedValue &&
                                    <Tooltip title={data.defaultValue} placement="top-start">
                                        <b className={classes.defaultValueText}>
                                            {data.defaultValue && (data.expert === '1' ?
                                                <FormattedMessage id={'mapper.label.expert'}/>
                                                : <FormattedMessage id={'mapper.label.combination'}/>)
                                            }
                                        </b>
                                    </Tooltip>
                                }
                            </GridItem>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        );
    };

    openExpertMode(position, property) {
        this.setState({
            expertModePosition: position,
            expertModePropertySelected: property,
            expertModePropertyValue: property.defaultValue ? property.defaultValue: null,
        })
    }

    closeExpertMode() {
        this.setState({
            expertModePosition: null,
            expertModePropertySelected: null,
            expertModePropertyValue: null,
            expertModeEntries: [{ name: "", value: "" }],
            expressionLanguageSuccess: null,
            expressionLanguageError: null,
            expressionLanguageLoading: null,
        });
    }

    onBlurSelect(name) {
        const {initialValues, change} = this.props;
        change(`configuration[${name}].selectedLabel`, false);
        initialValues.configuration[name].selectedLabel = false;
    }

    listFocused(name) {
        const {initialValues, change} = this.props;
        change(`configuration[${name}].selectedLabel`, !initialValues.configuration[name].selectedLabel);
        initialValues.configuration[name].selectedLabel = !initialValues.configuration[name].selectedLabel;
    }

    handleChange = (item, isExpertMode = false, expertModeValue = null) => {
        const {initialValues, change} = this.props;
        const propertyName = (isExpertMode && expertModeValue !== null) ? item.input.name : item.input;
        const mappingValue = (isExpertMode && expertModeValue !== null) ? expertModeValue : item.value;

        initialValues.configuration[propertyName].selectedLabel = false;
        change(`configuration[${propertyName}].selectedLabel`, false);

        if (item.input === "categories") {
            change(`configuration[${propertyName}].type`, 'category');
        }

        if (item.value !== 'none' && mappingValue !== '') {
            change(`configuration[${propertyName}].value`, mappingValue);
            initialValues.configuration[propertyName].value = mappingValue;
        } else {
            change(`configuration[${propertyName}].value`, null);
            initialValues.configuration[propertyName].value = null;
        }

        if (isExpertMode === true && mappingValue !== '') {
            change(`configuration[${propertyName}].expert`, '1');
            initialValues.configuration[propertyName].expert = '1';

            this.closeExpertMode();
        } else {
            change(`configuration[${propertyName}].expert`, '0');
            initialValues.configuration[propertyName].expert = '0';
        }
    };

    formatFieldsLabel(groupedMapperFields, initialValues) {
        const formattedMapperFields = {}

        for (const field of groupedMapperFields ) {
            formattedMapperFields[field] = {
                data: initialValues.configuration[field],
                subTitle: null
            }

            if (field === MapperFields.CATEGORIES) {
                formattedMapperFields[field].multiChoice = true;
            }
        }

        return formattedMapperFields;
    }

    getLabel(activeStep, initialValues) {
        if (initialValues) {
            switch (activeStep) {
                case 0:
                    const defaultFields =  this.formatFieldsLabel(getStockFeedMapperFields(initialValues), initialValues);
                    const inventoryFields = this.formatFieldsLabel(
                        getInventoryMapperFieldsNamesFromConfiguration(initialValues.configuration),
                        initialValues,
                    );

                    return {
                        ...defaultFields,
                        ...inventoryFields
                    };
            }
        }
    }

    firstTimeMapper(initialValues) {
        const isFirstTime = initialValues && filter(initialValues.configuration, (label) => label.value);

        if (isFirstTime && !isFirstTime.length && !this.state.isFirstTimeMapper) {
            this.setState({isFirstTimeMapper: true});
        }

        return isFirstTime;
    }


    renderLabelByFieldName(item) {
       if (item.indexOf(MapperPrefixCode.INVENTORY) !== -1) {
            const inventory = this.props.inventories?.find((inventory) => inventory.codeForMapper === item);

            if (inventory) {
                return getTranslation(inventory).name;
            }
        } else {
            return <FormattedMessage id={'mapper.form.label.' + item} />;
        }
    }

    render() {
        const {classes, initialValues, retrieved, intl} = this.props;
        const {activeStep, isFirstTimeMapper, loading} = this.state;

        const labels = this.getLabel(activeStep, loading ? undefined: initialValues);
        const expertModeFeedHeaders = this.getFeedHeaders(
            retrieved && retrieved["properties"],
            (this.state.expertModePropertySelected && this.state.expertModePropertySelected.input.name),
            true
        )

        const steps = [
            intl.formatMessage({id: "mapper.step.label.stock"}),
        ];

        const renderLabel = () => {
            return labels && Object.keys(labels).map((item, key) => {
                if (undefined === labels[item].data) {
                    return;
                }

                const label = this.renderLabelByFieldName(item);

                return (
                    <div key={key}>
                        {item === "sku" &&
                            <h3>
                                <FormattedMessage id={"mapper.update.mapperObligatorytitle"}/>
                            </h3>
                        }
                        <Field
                            key={key}
                            component={this.renderField}
                            name={item}
                            label={label}
                            type={"select"}
                            options={retrieved && retrieved["properties"]}
                            defaultValue={labels[item].data["value"]}
                            selectedLabel={labels[item].data["selectedLabel"]}
                            multiChoice={labels[item].multiChoice}
                            subTitle={labels[item].subTitle}
                            expert={labels[item].data["expert"]}
                            required
                        />
                        {item === "sku" &&
                            <hr className={classes.separateLine}/>
                        }
                    </div>
                )
            })
        };

        if (activeStep !== 4 || isFirstTimeMapper) {
            this.firstTimeMapper(initialValues);
        }


        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className={classes.formContainer}>
                    <Stepper alternativeLabel nonLinear={!isFirstTimeMapper} activeStep={activeStep}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label} className={index !== 0 ? classes.step : null}>
                                    <StepButton
                                        className={activeStep === index ? classes.selectedStepper : classes.stepper}
                                        onClick={this.handleStep(index)}
                                    >
                                        {label}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <div className={classes.labelStyle}>
                        {renderLabel()}
                        {this.props.mapperRetrieved && !loading &&
                            <div className={classes.actionsContainer}>
                                <Button
                                    color="info"
                                    simple
                                    component={Link}
                                    to={`/retailers/show/${encodeURIComponent(this.props.match.params.id)}/feeds/`}
                                >
                                    <ArrowBackIos/>
                                    <FormattedMessage id={"mapper.form.action.back"}/>
                                </Button>

                                {!isFirstTimeMapper ?
                                    <Button color="danger" simple onClick={this.del}>
                                        <Delete/>
                                        <FormattedMessage id={"mapper.update.action.delete"}/>
                                        <div className={classes.submitLoading}>
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={4}
                                                color={dangerColor}
                                                loading={this.props.deleteLoading}
                                            />
                                        </div>
                                    </Button>
                                    :
                                    <Button
                                        color="primary"
                                        disabled={activeStep === 0}
                                        onClick={this.handleStep(activeStep - 1)}
                                    >
                                        <ArrowBackIos/>
                                        <FormattedMessage id={"mapper.step.button.previousStep"}/>
                                    </Button>
                                }

                                {!isFirstTimeMapper &&
                                <Button
                                    round
                                    color="success"
                                    type="submit"
                                    disabled={initialValues && !initialValues.configuration.sku.value}
                                >
                                    <FormattedMessage id={"mapper.form.action.submit"}/>
                                    <div className={classes.submitLoading}>
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={4}
                                            color={"#FFF"}
                                            loading={this.props.updateLoading}
                                        />
                                    </div>
                                </Button>
                                }

                                {isFirstTimeMapper &&
                                <Button color="primary" onClick={this.handleStep(activeStep + 1)}>
                                    <FormattedMessage id={"mapper.step.button.nextStep"}/>
                                    <ArrowForwardIos/>
                                </Button>
                                }
                            </div>
                        }
                    </div>

                    <Popover
                        open={Boolean(this.state.expertModePosition)}
                        anchorEl={this.state.expertModePosition}
                        onClose={() => this.closeExpertMode()}
                        classes={{
                            paper: classes.expertModePopover,
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <b className={classes.propertyTitle}>
                            {this.state.expertModePropertySelected !== null &&
                                this.props.intl.formatMessage({id: "mapper.form.label." + this.state.expertModePropertySelected.input.name })
                            } :
                        </b>
                        <ReactTextareaAutocomplete
                            className="my-textarea"
                            style={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                padding: 5,
                                fontFamily: "Roboto",
                                height: "150px"
                            }}
                            containerStyle={{
                                margin: "10px",
                                width: "unset"
                            }}
                            value={this.state.expertModePropertyValue}
                            ref={rta => {this.rta = rta}}
                            innerRef={textarea => { this.textarea = textarea}}
                            loadingComponent={() => <span>{intl.formatMessage({id: "mapper.label.expert.loading"})}</span>}
                            onChange={e => this.setState({expertModePropertyValue: e.target.value})}
                            trigger={{
                                "#": {
                                    dataProvider: query => {
                                        return expertModeFeedHeaders.filter(
                                            headers => headers.value.includes(query)
                                        )
                                    },
                                    component: ({ entity: { label } }) => label,
                                    output: (item, trigger) => item.value,
                                    allowWhitespace: true
                                }
                            }}
                        />
                        <Accordion className={classes.accordionExpertMode} defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                                className={classes.accordionSummaryExpertMode}
                            >
                                <div className={classes.accordionSummaryExpandedExpertMode}>
                                    <FormattedMessage id={"mapper.expertMode.button.test"}/>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetailsExpertMode}>
                                <div>
                                    <FormattedMessage id={"mapper.expertMode.notice.entries"}/>
                                </div>
                                <div>
                                    {this.state.expertModeEntries.map((entry, i) => {
                                        return (
                                            <div className={classes.expertModeEntriesBox} key={i}>
                                                <input
                                                    name="name"
                                                    className={classes.expertModeEntriesBoxInput}
                                                    placeholder={this.props.intl.formatMessage({id:"mapper.expertMode.box.placeholder.name"})}
                                                    value={entry.name}
                                                    onChange={e => this.handleInputChange(e, i)}
                                                />
                                                <input
                                                    name="value"
                                                    className={classes.expertModeEntriesBoxInput}
                                                    placeholder={this.props.intl.formatMessage({id:"mapper.expertMode.box.placeholder.value"})}
                                                    value={entry.value}
                                                    onChange={e => this.handleInputChange(e, i)}
                                                />
                                                <div>
                                                    {this.state.expertModeEntries.length !== 1 &&
                                                        <Remove className={classes.expertModeEntriesBoxIcon} onClick={() => this.handleRemoveClick(i)}/>
                                                    }
                                                    {this.state.expertModeEntries.length - 1 === i &&
                                                        <Add className={classes.expertModeEntriesBoxIcon} onClick={this.handleAddClick}/>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </AccordionDetails>
                            {(this.state.expressionLanguageSuccess || this.state.expressionLanguageError) &&
                                <AccordionDetails className={classes.accordionDetailsExpertMode}>
                                    {this.state.expressionLanguageError ?
                                        <div className={classes.expertModeResultError}>
                                            {this.state.expressionLanguageError}
                                        </div>
                                        :
                                        <div className={classes.expertModeResultSuccess}>
                                            {this.state.expressionLanguageSuccess}
                                        </div>
                                    }
                                </AccordionDetails>
                            }
                            <AccordionActions>
                                <Button
                                    simple
                                    color="info"
                                    className={classes.expertModeButton}
                                    children={null}
                                    onClick={() => {
                                        this.checkExpressionLanguage(
                                            this.state.expertModePropertyValue,
                                            this.state.expertModeEntries
                                        )}
                                    }
                                >
                                    {this.state.expressionLanguageLoading ?
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={4}
                                            color={primaryColor}
                                            loading={true}
                                        />
                                    :
                                        <FormattedMessage id={"mapper.expertMode.button.test"}/>
                                    }
                                </Button>
                            </AccordionActions>
                        </Accordion>
                        <Divider />
                        <div className={classes.expertModeActionArea}>
                            <Button
                                simple
                                color="info"
                                onClick={() => this.closeExpertMode()}
                                className={classes.expertModeButton}
                            >
                                <FormattedMessage id={"mapper.expertMode.button.cancel"}/>
                            </Button>
                            <Button
                                round
                                color="success"
                                onClick={() => {
                                    this.handleChange(
                                        this.state.expertModePropertySelected,
                                        true,
                                        this.state.expertModePropertyValue
                                    )
                                }}
                                className={classes.expertModeButton}
                            >
                                <FormattedMessage id={"mapper.expertMode.button.confirm"}/>
                            </Button>
                        </div>
                    </Popover>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    const retrieved = state.feed.update.retrieved;
    const updateLoading = state.mapper.update.updateLoading;
    const deleteLoading = state.mapper.del.loading;
    const mapperRetrieved = state.mapper.update.retrieved;
    const inventories = state.currentOrganization.retrieved?.inventories;

    return {
        retrieved,
        updateLoading,
        deleteLoading,
        mapperRetrieved,
        inventories,
        initial: state.form.mapper && state.form.mapper.initial && state.form.mapper.initial.configuration,
        values: state.form.mapper && state.form.mapper.values && state.form.mapper.values.configuration,
    };
};

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    del: item => dispatch(del(item)),
});

export default reduxForm({
    form: "stockMapper",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
}, mapStateToProps)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(formShowStyle)(injectIntl(StockForm))));
