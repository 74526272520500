import {fetch, normalize} from "../../utils/dataAccess";

export function create(retailerId, translation) {
    return new Promise((resolve, reject) => {
        fetch(`retailer_translations`,  {
            method: "POST",
            body: JSON.stringify({
                translatable: retailerId,
                ...translation,
            })
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error));
    })
}