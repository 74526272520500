import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

import ActionsContainer from "components/containers/ActionsContainer";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import CardBody from "components/card/cardBody";
import DefaultContainer from "components/containers/DefaultContainer";
import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";
import SaveButton from "components/button/SaveButton";
import SelectTitle from "components/radioGroup/SelectTitle";
import SuccessMessage from "components/messages/SuccessMessage";

import PrinterName from "./components/PrinterName";
import Title from "./components/Title";
import TopRightLoader from "components/loaders/TopRightLoader";

import list from "api/printers/list";

import {Authorizations, hasAuthorization} from "utils/authorizations";

function Index(props) {
    const [documentPrinterId, setDocumentPrinterId] = useState(null);
    const [labelPrinterId, setLabelPrinterId] = useState(null);

    const [availablePrinters, setAvailablePrinters] = useState([]);

    const [loading, setLoading] = useState(false);

    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);

    const hasPickingPrintersAuthorization = hasAuthorization(props.authorizations, Authorizations.PICKING_PRINTERS);

    // componentDidMount
    useEffect(() => {
        if (!hasPickingPrintersAuthorization) {
            return;
        }

        setLoading(true);

        list().then((response) => {
            setAvailablePrinters(response);
            setLoading(false);
        });
    }, []);

    // wait organization id and availablePrinters to be filled
    useEffect(() => {
        if (props?.organization?.id && availablePrinters) {
            getPrinterFromLocalStorage();
        }
    }, [props.organization, availablePrinters]);

    useEffect(() => {
        setDisplaySuccessMessage(false);
    }, [documentPrinterId, labelPrinterId])

    function getPrinterNameById(id) {
        if (id === null) {
            return '';
        }

        const printer = availablePrinters.filter((printer) => printer.id === Number(id)).shift();

        if (printer) {
            return printer.name
        }
    }

    function getPrinterFromLocalStorage() {
        const preferredPrinters = JSON.parse(localStorage.getItem(`${props.organization?.id}_preferredPrinters`));

        if (preferredPrinters) {
            setLabelPrinterId(preferredPrinters['labelPrinterId']);
            setDocumentPrinterId(preferredPrinters['documentPrinterId']);
        }
    }

    function saveSelectedPrinters() {
        setDisplaySuccessMessage(false);

        const organizationId = props.organization.id;

        const preferredPrinters = {
            'organizationId': organizationId,
            'labelPrinterId': labelPrinterId,
            'documentPrinterId': documentPrinterId
        }

        localStorage.setItem(`${organizationId}_preferredPrinters`, JSON.stringify(preferredPrinters));

        setDisplaySuccessMessage(true);
    }

    if (!hasPickingPrintersAuthorization) {
        return <Redirect to={'/'} />;
    }
    
    return (
        <DefaultContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card style={{ minHeight: `${window.innerHeight * 0.94}px`}}>
                        <TopRightLoader loading={loading} />
                        <CardHeader color="info">
                            <GridContainer>
                                <GridItem xs={9} sm={10}>
                                    <Title />
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} md={4}>
                                    <SelectTitle titleTranslationKey={'picking.printers.field.label'} />
                                    <RadioGroup
                                        wrapChosenValue={true}
                                        alignListWithButton={true}
                                        placeholder={<FormattedMessage id={'picking.printers.field.placeholder'} />}
                                        disabled={availablePrinters.length === 0}
                                        onChange={(event) => setLabelPrinterId(event.target.value)}
                                        selectedValueLabel={getPrinterNameById(labelPrinterId) ?? ''}
                                        value={labelPrinterId}
                                    >
                                        {availablePrinters.map((printer, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={printer.id}
                                                label={<PrinterName printer={printer} />}
                                            />
                                        ))}
                                    </RadioGroup>
                                </GridItem>
                                <GridItem xs={12} md={4}>
                                    <SelectTitle titleTranslationKey={'picking.printers.field.document'} />
                                    <RadioGroup
                                        wrapChosenValue={true}
                                        alignListWithButton={true}
                                        placeholder={<FormattedMessage id={'picking.printers.field.placeholder'} />}
                                        disabled={availablePrinters.length === 0}
                                        onChange={(event) => setDocumentPrinterId(event.target.value)}
                                        selectedValueLabel={getPrinterNameById(documentPrinterId) ?? ''}
                                        value={documentPrinterId}
                                    >
                                        {availablePrinters.map((printer, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={printer.id}
                                                label={<PrinterName printer={printer} />}
                                            />
                                        ))}
                                    </RadioGroup>
                                </GridItem>
                            </GridContainer>
                            <GridContainer sx={{marginTop: '48px', textAlign: 'right'}}>
                                <GridItem xs={12}>
                                    <ActionsContainer>
                                        <SaveButton
                                            onClick={() => saveSelectedPrinters()}
                                            disabled={availablePrinters.length === 0}
                                        >
                                            <FormattedMessage id={'picking.printers.button.save'} />
                                        </SaveButton>
                                    </ActionsContainer>
                                </GridItem>
                                <GridItem sx={{textAlign: 'right'}}>
                                    {displaySuccessMessage && (
                                        <SuccessMessage messageId={'picking.printers.message.success'} />
                                    )}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </DefaultContainer>
    );
}

const mapStateToProps = state => {
    return {
        authorizations: state.authentication.authorizations,
        organization: state.currentOrganization.retrieved,
    }
};

export default connect(mapStateToProps)(Index);
