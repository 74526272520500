import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
    ClickAwayListener,
    RadioGroup as DefaultRadioGroup
} from "@mui/material";

import {makeStyles} from "@mui/styles";

import FakeSelectInput from "./FakeSelectInput";

const useStyles = makeStyles(theme => ({
    alignListWithButton: {
        position: 'relative'
    },
    alignListToRight: {
        'right': 0,
    },
    radioGroupFilter: {
        alignContent: 'start',
        marginTop: '12px',
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
        maxHeight: '285px',
        flexWrap: 'nowrap',
        overflow: 'scroll'
    },
    listBiggerThanButton: {
        width: "108%"
    },
}));

export default function RadioGroup(props) {
    const classes = useStyles();

    const [showRadioList, toggleRadioList] = useState(false);

    const {
        disabled,
        selectedValueLabel,
        onChange,
        alignListWithButton,
        alignListToRight,
        placeholder,
        endAdornment,
        onClickAway,
        wrapChosenValue,
        ...rest
    } = props;

    return (
        <ClickAwayListener onClickAway={() => {
            if (showRadioList === false) {
                return;
            }
            toggleRadioList(false)
            onClickAway
        }}>
            <div
                 className={classNames({
                     [classes.alignListWithButton]: alignListWithButton || alignListToRight
                 })}
            >
                <FakeSelectInput
                    wrapChosenValue={wrapChosenValue}
                    onClick={() => toggleRadioList(!showRadioList)}
                    text={selectedValueLabel ?? props.value}
                    disabled={disabled}
                    open={showRadioList}
                    placeholder={placeholder}
                    endAdornment={endAdornment}
                />
                {!disabled && showRadioList && (
                    <DefaultRadioGroup
                        className={classNames({
                            [classes.radioGroupFilter]: true,
                            [classes.listBiggerThanButton]: !alignListWithButton,
                            [classes.alignListToRight]: alignListToRight,
                        })}
                        onChange={(event) => {
                            if (onChange) {
                                onChange(event);
                            }
                            toggleRadioList(false);
                        }}
                        {...rest}
                    >
                        {props.children}
                    </DefaultRadioGroup>
                )}
                {/* todo: display formik error here */}
            </div>
        </ClickAwayListener>
    )
}

RadioGroup.defaultProps = {
    alignListWithButton: false,
    alignListToRight: false
};

RadioGroup.propTypes = {
    alignListWithButton: PropTypes.bool,
    alignListToRight: PropTypes.bool,
    disabled: PropTypes.bool,
    selectedValueLabel: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
    endAdornment: PropTypes.node
};
